import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { BigNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Box } from '@swell-ui/Box'
import { Button } from '@swell-ui/Button'
import { CircularProgress } from '@swell-ui/CircularProgress'
import { Divider } from '@swell-ui/Divider'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { RswethIcon } from '@swell-ui/icons/RswethIcon'
import { EthInput, SwEthInput } from '@swell-ui/inputs'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { TokenSelectWidget } from '@/components/TokenSelectWidget'
import { SwellStatistics } from '@/components/SwellStatistics'
import { StakingConfirmationProgressWidget } from '@/components/StakingConfirmationProgressWidget'
import { useRestakingRate } from '@/state/restakingStats/hooks'
import {
  useFormatNativeCurrency,
  useParseNativeCurrency,
} from '@/hooks/useNativeCurrencyFormatting'
import { useFormatRswEth, useParseRswEth } from '@/hooks/useRswEthFormatting'
import { useDisplayRswEthPriceFiat } from '@/hooks/useRswEthDisplay'
import {
  useRestakingActions,
  useRestakeNativeCurrency,
} from '@/state/restaking/hooks'
import { useRestakingDisplayStrings } from '@/hooks/useRestakingDisplayStrings'
import { StakingSubmissionStatus, useRestakeLens } from '@/hooks/useRestakeLens'
import {
  GLOBAL_NOTIFICATION_TYPES,
  useGlobalNotification,
} from '@/swell-ui/GlobalNotification'
import { useChainInfo, useDeploymentSetConfig } from '@/state/deployments/hooks'
import { useEthUsdMarketRate, useRswEthUsdMarketRate } from '@/state/fiat/hooks'
import { useDisplayNativeCurrencyPriceFiat } from '@/hooks/useNativeCurrencyDisplay'
import { displayCrypto } from '@/util/displayCrypto'
import { displayFiat } from '@/util/displayFiat'
import { trimDecimalPlaces } from '@/util/number'
import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { ActionChooser } from './ActionChooser'
import { AvailableChip } from './AvailableChip'
import { CryptoUtilities } from './CryptoUtilities'
import { ExchangeInfo } from './ExchangeInfo'
import { SwapInfo } from './SwapInfo'
import { VaultView } from './VaultView'
import { ACTIONS, TRANSACTION_TOAST_TITLE } from './constants'
import eigenUrl from '@/assets/images/eigenlayer-long-lg.png'
import {
  getNativeCurrencyToExchangeForRsweth,
  getRswethReceivedForNativeCurrency,
} from '@/util/big'
import { ZapCall } from '@/state/zap/hooks'
import { PreparedZap } from '@/state/zap/util'
import { Token } from '@/types/tokens'
import { useAllowance } from '@/hooks/erc20/useAllowance'
import { useApprove } from '@/hooks/erc20/useApprove'
import { ZapRoute } from '@/state/zap/types'

const FloatingEigenLogo = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;

  transform: translate(-50%, 50%) translate(0, 10px);
`

const StakingWidgetBox = styled(Box)`
  ${({ theme }) => `
    position: relative;
    width: 340px;
    margin: 0 auto;
    padding: 24px 24px 12px;
    margin-top: 18px;
    color: ${theme.mainColor};
    border: none;

    > div {
      margin-bottom: 12px;
    }

    ${theme.breakpoints.up('sm')} {
      width: 420px;
      padding: 24px 32px 12px;


      > div {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 12px;
        }
      }
    }}
  `}

  .Mui-disabled.MuiInputBase-root:before {
    border-bottom-style: solid;
    opacity: 0.6;
  }
`

const UtilitiesContainer = styled.div`
  position: absolute;
  top: 99px;
  right: 32px;
`

const EthInputWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;

  .MuiFormControl-root {
    width: 100%;
  }

  input,
  div > div > p {
    font-size: ${({ theme }) => theme.typography.body.large.fontSize};
    font-weight: 600;
  }
`

const UsdTypography = styled(Typography)`
  height: 19.2px;
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  margin-bottom: 16px;
  margin-top: 2px;
  letter-spacing: -0.03em;
`

const StakingWidgetEthInput = styled(EthInput)`
  ${({ theme }) => `
    .MuiFormHelperText-root {
      margin-top: 2px;
      margin-bottom: 16px;
    }

    ${theme.breakpoints.up('sm')} {
      max-width: unset;
    }
  `}
`

const SymbolWrapper = styled.div`
  display: flex;
  align-items: center;
`

const StyledEthIcon = styled(EthIcon)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`

const StyledRswethIcon = styled(RswethIcon)`
  width: 35px;
  height: 35px;
  margin-right: 8px;
`

const StakeWidgetButton = styled(Button)`
  position: relative;
  width: 100%;
`

const StakeConnectButton = styled(ConnectWalletButton)`
  width: 100%;
`

const InputCircularProgress = styled(CircularProgress)`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`

const ButtonCircularProgress = styled(CircularProgress)`
  &.MuiCircularProgress-colorPrimary {
    color: ${({ theme }) => theme.colors.white['150']};
  }
`

const ButtonInner = styled.div`
  position: relative;
`

const ButtonProgressPlacer = styled.div`
  position: absolute;
  top: -7px;
  left: -32px;
`

type Loadable<T> =
  | {
      isLoading: false
      value: T
    }
  | { isLoading: true }

function inputValueToCryptoAmount(
  inputValue: string,
  decimals: number
): string {
  const decimalPlaces = Math.min(decimals, 12)
  const cryptoAmountString = trimDecimalPlaces(inputValue, decimalPlaces)
  return parseUnits(cryptoAmountString, decimals).toString()
}

type StakingWidgetProps = {
  stakingEnabled: Loadable<boolean>
  action: string
  setAction: React.Dispatch<React.SetStateAction<string>>
  srcToken: Token
  setSrcToken: React.Dispatch<React.SetStateAction<Token>>
  defaultSrcToken: Token
  destToken: Token
  srcTokenInputValue: string
  setSrcTokenInputValue: React.Dispatch<React.SetStateAction<string>>
  destTokenInputValue: string
  setDestTokenInputValue: React.Dispatch<React.SetStateAction<string>>
  slippagePercent: number
  setSlippagePercent: React.Dispatch<React.SetStateAction<number>>
  defaultSlippagePercent: number
  srcTokenBalance: BigNumber

  zapRoute: ZapRoute | undefined
  routeRefetching: boolean
  ethBalance: BigNumber | undefined
  tokenBalancesData: any | undefined // TODO: types
  fromAmount: BigNumber | undefined
  toAmount: BigNumber | undefined

  allowance: ReturnType<typeof useAllowance> // TODO: formalize
  approve: ReturnType<typeof useApprove> // TODO: formalize
  zap: ZapCall
  zapBuilding: boolean
  preparedZap: PreparedZap
}

function RestakingWidget({
  stakingEnabled,
  destTokenInputValue,
  preparedZap,
  setDestTokenInputValue,
  setSrcTokenInputValue,
  srcTokenInputValue,
  defaultSrcToken,
  zap,
  destToken,
  srcToken,
  slippagePercent,
  srcTokenBalance,
  ethBalance,
  tokenBalancesData,
  setSrcToken,
  action,
  setAction,
  allowance,
  setSlippagePercent,
  approve,
  zapBuilding,
  routeRefetching,
  zapRoute,
  fromAmount,
  toAmount,
  defaultSlippagePercent,
}: StakingWidgetProps) {
  // Get user, config data, helpers
  const { account, chainId } = useSwellWeb3()
  const { chainId: deploymentChainId } = useDeploymentSetConfig()
  const { explorer } = useChainInfo()
  const { notify, removeNotification } = useGlobalNotification()

  // Get referrer address if exists
  const [searchParams] = useSearchParams()
  const referrerAddress = searchParams.get('ref') || undefined

  // Action chooser state
  const [toastAction, setToastAction] = useState<string>(ACTIONS.ZAP)

  // Form State
  const [touched, setTouched] = useState<boolean>(false)
  const [tokenSelectOpen, setTokenSelectOpen] = useState<boolean>(false)

  const zapTokenSelectDisabled = zap.status !== zap.STATUS.IDLE

  // Instantiate formatters and parsers
  const formatRswEth = useFormatRswEth()
  const parseRswEth = useParseRswEth()
  const formatNativeCurrency = useFormatNativeCurrency()
  const parseNativeCurrency = useParseNativeCurrency()
  const displayRswEthPriceFiat = useDisplayRswEthPriceFiat()
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()

  // Swell staking function
  const restakeNativeCurrency = useRestakeNativeCurrency()
  const { clearRestaking } = useRestakingActions()

  // Get ETH/swETH rates for staking
  const ethUsdMarketRate = useEthUsdMarketRate()
  const rswEthUsdMarketRate = useRswEthUsdMarketRate()
  const restakingRate = useRestakingRate()

  // State / data for staking
  const { nativeCurrencyDisplayStr, rswEthAmountDisplayStr } =
    useRestakingDisplayStrings(restakingRate.data?.rswETHToETHRate)

  const { status: stakingSubmissionStatus, txHash, error } = useRestakeLens()

  /**
   * Responsible for watching the status of the submission and displaying
   * a progress bar upon a pending stake,
   * a banner to the user upon successful staking,
   * or encountering an error while staking.
   */
  useEffect(() => {
    if (stakingSubmissionStatus === StakingSubmissionStatus.FULFILLED) {
      setTouched(false)
      setSrcTokenInputValue('')
      setDestTokenInputValue('')

      return
    }

    if (stakingSubmissionStatus === StakingSubmissionStatus.ERROR && error) {
      const nId = notify(error, GLOBAL_NOTIFICATION_TYPES.ERROR)

      return () => {
        removeNotification(nId)
      }
    }
  }, [
    error,
    explorer,
    nativeCurrencyDisplayStr,
    notify,
    removeNotification,
    setSrcTokenInputValue,
    setDestTokenInputValue,
    setTouched,
    stakingSubmissionStatus,
    txHash,
  ])

  const srcTokenIsEth = srcToken && srcToken.symbol === TOKEN_LIST_ETH.symbol

  // UI logic and state
  const isRestake = (): boolean => {
    return action === ACTIONS.RESTAKE || srcTokenIsEth
  }

  const isZap = (): boolean => {
    return action === ACTIONS.ZAP && !srcTokenIsEth
  }

  useEffect(() => {
    if (srcTokenInputValue) setTouched(true)
  }, [srcTokenInputValue])

  const ethValueErrorMsg = (): string | null => {
    if (isRestake()) {
      if (!ethBalance || !touched) return null

      if (srcTokenInputValue === '') return 'Must enter a value'

      const ethAmountBN = parseNativeCurrency(srcTokenInputValue)
      if (ethAmountBN.eq(0)) return 'Value cannot be 0'
      if (ethAmountBN.gt(ethBalance)) return 'Insufficient balance'
    } else if (isZap()) {
      if (!tokenBalancesData || !touched) return null

      if (srcTokenInputValue === '') return 'Must enter a value'

      const tokenAmount = inputValueToCryptoAmount(
        srcTokenInputValue,
        srcToken.decimals
      )
      const tokenAmountBN = BigNumber.from(tokenAmount)
      if (tokenAmountBN.eq(0)) return 'Value cannot be 0'
      if (tokenAmountBN.gt(srcTokenBalance)) return 'Insufficient balance'
    }

    return null
  }

  const preventInteraction = (): boolean => {
    if (restakingRate.isLoading || stakingEnabled.isLoading) {
      return true
    }
    if (account) {
      if (ethBalance === undefined) return true
      if (isZap() && tokenBalancesData === undefined) return true
    }
    if (!stakingEnabled.value) return true
    if (chainId !== deploymentChainId) {
      return true
    }
    return false
  }
  const ethInputDisabled = () => {
    return preventInteraction() || (isZap() && zap.status !== zap.STATUS.IDLE)
  }

  const restakeDisabled = (): boolean => {
    if (preventInteraction()) return true
    if (ethValueErrorMsg()) return true
    if (srcTokenInputValue === '') return true

    return false
  }

  const showApprove = (): boolean => {
    const compareValue = srcTokenInputValue
      ? BigNumber.from(
          inputValueToCryptoAmount(srcTokenInputValue, srcToken.decimals)
        )
      : -1
    const hasEnoughAllowance = allowance.amount.gte(compareValue)

    return !srcTokenIsEth && !hasEnoughAllowance
  }

  const approveDisabled = () => {
    return (
      allowance.isLoading ||
      approve.isLoading ||
      !srcToken ||
      !srcTokenInputValue ||
      !destTokenInputValue ||
      !!ethValueErrorMsg()
    )
  }

  const zapDisabled = () => {
    if (ethValueErrorMsg()) return true
    if (zapRouteLoading()) return true
    if (preparedZap.args === undefined) return true
    if (zap.status !== zap.STATUS.IDLE) return true
    return false
  }

  const zapRouteLoading = () => {
    return (
      isZap() &&
      srcTokenInputValue !== '' &&
      (zapRoute === undefined || routeRefetching)
    )
  }

  /* Interaction handlers */

  const handleActionClick = (action: string) => {
    if (action === ACTIONS.RESTAKE) {
      setSrcToken(TOKEN_LIST_ETH)
    } else if (action === ACTIONS.ZAP) {
      setSrcToken(defaultSrcToken)
    }

    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setAction(action)
  }

  const handleTokenSelect = (token: any) => {
    setSrcToken(token)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
  }

  const handleEthInputChange = (event: any) => {
    if (!restakingRate.data) return

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    let decimalPlaces = 18
    if (isZap()) {
      decimalPlaces = Math.min(srcToken.decimals, 12)
    }

    const valueClean = trimDecimalPlaces(value, decimalPlaces)
    setSrcTokenInputValue(valueClean)

    if (isRestake()) {
      const { rswETHToETHRate } = restakingRate.data!
      const nativeAmount = parseNativeCurrency(valueClean)
      const rswEthAmount = getRswethReceivedForNativeCurrency({
        rswethToEthRate: rswETHToETHRate,
        toSendNativeCurrency: nativeAmount,
      })
      setDestTokenInputValue(formatRswEth(rswEthAmount))
    }
  }

  const handleMaxClick = () => {
    if (isRestake()) {
      if (!ethBalance) return
      handleEthInputChange({
        target: { value: formatNativeCurrency(ethBalance) },
      })
    } else if (isZap()) {
      if (!srcTokenBalance) return
      handleEthInputChange({
        target: { value: formatUnits(srcTokenBalance, srcToken.decimals) },
      })
    }
  }

  const handleSwEthInputChange = (event: any) => {
    if (!restakingRate.data) throw new Error(`No staking data`)

    const value = event.target.value
    if (value === '') {
      setSrcTokenInputValue('')
      setDestTokenInputValue('')
      return
    }

    const valueClean = trimDecimalPlaces(value, 18)
    setDestTokenInputValue(valueClean)

    if (isRestake()) {
      const { rswETHToETHRate } = restakingRate.data!
      const rswEthAmount = parseRswEth(valueClean)
      const nativeAmount = getNativeCurrencyToExchangeForRsweth({
        rswethToEthRate: rswETHToETHRate,
        toReceiveRsweth: rswEthAmount,
      })
      setSrcTokenInputValue(formatNativeCurrency(nativeAmount))
    }
  }

  const handleStakeClick = () => {
    const ethAmountBN = parseNativeCurrency(srcTokenInputValue)
    restakeNativeCurrency(ethAmountBN, referrerAddress)
  }

  const handleApproveClick = async () => {
    setToastAction(ACTIONS.ZAP)
    const approved = await approve.sendTransaction(srcTokenInputValue, srcToken)
    if (approved) {
      allowance.set(approved)
    }
  }

  const handleZapClick = async () => {
    if (!preparedZap.args) return
    setToastAction(ACTIONS.ZAP)
    await zap.call(...preparedZap.args)
    setSrcTokenInputValue('')
    setDestTokenInputValue('')
    setTouched(false)
  }

  /* Transaction toast logic */
  // TODO: transaction toast needs to get fully revamped at some point
  useEffect(() => {
    if (
      stakingSubmissionStatus === StakingSubmissionStatus.FULFILLED ||
      approve.status === approve.STATUS.FULFILLED ||
      zap.status === zap.STATUS.FULFILLED
    ) {
      setTimeout(() => {
        clearRestaking()
        approve.clear()
        zap.clear()
      }, 5000)
    }
  }, [approve, clearRestaking, stakingSubmissionStatus, zap])

  const toastOpen = (): boolean => {
    if (isZap()) {
      return (
        approve.status !== approve.STATUS.IDLE || zap.status !== zap.STATUS.IDLE
      )
    }

    if (stakingSubmissionStatus === StakingSubmissionStatus.ERROR) return false

    return stakingSubmissionStatus !== StakingSubmissionStatus.IDLE
  }

  const toastTitle = (): string => {
    if (approve.status === approve.STATUS.PROMPTING) {
      return TRANSACTION_TOAST_TITLE.APPROVE_PROMPTING
    } else if (approve.status === approve.STATUS.PENDING) {
      return TRANSACTION_TOAST_TITLE.APPROVE_PENDING
    } else if (zapBuilding) {
      return TRANSACTION_TOAST_TITLE.ZAP_BUILDING
    } else if (zap.status === zap.STATUS.PROMPTING) {
      return TRANSACTION_TOAST_TITLE.ZAP_PROMPTING
    } else if (zap.status === zap.STATUS.PENDING) {
      return TRANSACTION_TOAST_TITLE.ZAP_PENDING
    } else if (stakingSubmissionStatus === StakingSubmissionStatus.PROMPTING) {
      return TRANSACTION_TOAST_TITLE.RESTAKE_PROMPTING
    } else if (stakingSubmissionStatus === StakingSubmissionStatus.PENDING) {
      return TRANSACTION_TOAST_TITLE.RESTAKE_PENDING
    }
    return TRANSACTION_TOAST_TITLE.COMPLETED
  }

  const toastMessage = (): string => {
    if (approve.isLoading) {
      const formattedApproveAmount = displayCrypto(
        approve.amount,
        approve.token.decimals,
        {
          precision: 4,
          localize: true,
        }
      )
      return `Approve ${formattedApproveAmount} ${approve.token.symbol}`
    } else if (toastAction === ACTIONS.ZAP) {
      if (approve.status !== approve.STATUS.IDLE) {
        const formattedApproveAmount = displayCrypto(
          approve.amount,
          approve.token.decimals,
          {
            precision: 4,
            localize: true,
          }
        )

        return `Approve ${formattedApproveAmount} ${approve.token.symbol}`
      } else if (zap.status !== zap.STATUS.IDLE) {
        if (!zapRoute || !fromAmount || !toAmount) {
          return `Zap for rswETH`
        }

        const formattedFromAmount = displayCrypto(
          fromAmount,
          zapRoute.fromToken.decimals,
          {
            precision: 4,
            localize: true,
          }
        )

        const formattedToAmount = displayCrypto(
          toAmount,
          zapRoute.toToken.decimals,
          {
            precision: 4,
            localize: true,
          }
        )

        return `Zap ${formattedFromAmount} ${srcToken.symbol} for ${formattedToAmount} rswETH`
      }
    }
    return `Restake ${nativeCurrencyDisplayStr} for ${rswEthAmountDisplayStr}`
  }

  const toastTxHash = () => {
    if (toastAction === ACTIONS.ZAP && zap.txHash) {
      return zap.txHash
    }
    return txHash
  }

  const transactionConfirming = (): boolean => {
    return (
      stakingSubmissionStatus === StakingSubmissionStatus.PROMPTING ||
      approve.status === approve.STATUS.PROMPTING ||
      zapBuilding ||
      zap.status === zap.STATUS.PROMPTING
    )
  }

  const transactionPending = (): boolean => {
    return (
      stakingSubmissionStatus === StakingSubmissionStatus.PENDING ||
      approve.status === approve.STATUS.PENDING ||
      zap.status === zap.STATUS.PENDING
    )
  }

  const transactionComplete = (): boolean => {
    return (
      stakingSubmissionStatus === StakingSubmissionStatus.FULFILLED ||
      approve.status === approve.STATUS.FULFILLED ||
      zap.status === zap.STATUS.FULFILLED
    )
  }

  const onToastClose = () => {
    clearRestaking()
    approve.clear()
    zap.clear()
  }

  return (
    <StakingWidgetBox>
      <FloatingEigenLogo src={eigenUrl} width={62} height={26} />
      <ActionChooser
        actions={['Restake']}
        onActionClick={() => {}}
        defaultAction={'Restake'}
      />
      {action !== ACTIONS.VAULT && (
        <>
          <AvailableChip
            token={srcToken}
            tokenBalances={tokenBalancesData?.tokenBalances}
          />
          <UtilitiesContainer>
            <CryptoUtilities />
          </UtilitiesContainer>
          <div>
            {/* Can i make this more reusable somehow? TokenInputLabel */}
            <FlexRow justify="space-between" align="center">
              <Typography variant="body" size="xlarge" fstyle="bold">
                {srcToken === TOKEN_LIST_ETH ? 'Restake' : 'Zap'}
              </Typography>
              {action === ACTIONS.RESTAKE && (
                <SymbolWrapper>
                  <StyledEthIcon />
                  <Typography variant="body" size="large" fstyle="bold">
                    ETH
                  </Typography>
                </SymbolWrapper>
              )}
              {action === ACTIONS.ZAP && (
                <TokenSelectWidget
                  isOpen={tokenSelectOpen}
                  onClose={() => setTokenSelectOpen(false)}
                  onTokenSelect={handleTokenSelect}
                  tokenBalances={tokenBalancesData?.tokenBalances}
                  defaultToken={srcToken}
                  disabled={zapTokenSelectDisabled}
                />
              )}
            </FlexRow>
            <EthInputWrapper>
              <StakingWidgetEthInput
                variant="standard"
                value={srcTokenInputValue}
                onChange={handleEthInputChange}
                error={!!ethValueErrorMsg()}
                helperText={ethValueErrorMsg()}
                disabled={ethInputDisabled()}
                onMaxClick={handleMaxClick}
              />
            </EthInputWrapper>
            {/* TODO: price should be displayed as 'xx USD' */}
            {!ethValueErrorMsg() && ethUsdMarketRate.data && (
              <UsdTypography variant="body" size="xsmall">
                {isRestake() && (
                  <>
                    {displayNativeCurrencyPriceFiat(
                      parseNativeCurrency(srcTokenInputValue || '0'),
                      ethUsdMarketRate.data.rate
                    )}
                  </>
                )}
                {isZap() && (
                  <>
                    {zapRoute &&
                      `${displayFiat(Number(zapRoute.fromAmountUSD))}`}
                  </>
                )}
              </UsdTypography>
            )}
            <FlexRow justify="space-between" align="center">
              <Typography variant="body" size="xlarge" fstyle="bold">
                Receive
              </Typography>
              <SymbolWrapper>
                <StyledRswethIcon />
                <Typography
                  variant="headline"
                  size="h5"
                  fstyle="bold"
                  letterSpacing="small"
                >
                  rswETH
                </Typography>
              </SymbolWrapper>
            </FlexRow>
            <EthInputWrapper>
              {(action !== ACTIONS.ZAP || !zapRouteLoading()) && (
                <SwEthInput
                  variant="standard"
                  value={destTokenInputValue}
                  onChange={handleSwEthInputChange}
                  disabled={preventInteraction() || isZap()}
                />
              )}
              {zapRouteLoading() && (
                <>
                  <SwEthInput variant="standard" disabled={true} />
                  <InputCircularProgress size={35} />
                </>
              )}
            </EthInputWrapper>
            {rswEthUsdMarketRate.data && (
              <UsdTypography variant="body" size="xsmall">
                {displayRswEthPriceFiat(
                  parseRswEth(destTokenInputValue || '0'),
                  rswEthUsdMarketRate.data.rate
                )}
              </UsdTypography>
            )}
          </div>
          <Divider />
          {isRestake() && <ExchangeInfo restake={true} />}
          {isZap() && (
            <SwapInfo
              isLoading={!zapRoute || routeRefetching}
              srcTokenSymbol={srcToken?.symbol || ''}
              destTokenSymbol={destToken?.symbol || ''}
              bestRouteInfo={zapRoute}
              setSlippagePercent={setSlippagePercent}
              slippagePercent={slippagePercent}
              defaultSlippagePercent={defaultSlippagePercent}
            />
          )}
          <div>
            {account && isRestake() && (
              <StakeWidgetButton
                disabled={restakeDisabled()}
                onClick={handleStakeClick}
              >
                <ButtonInner>
                  {(stakingSubmissionStatus ===
                    StakingSubmissionStatus.PROMPTING ||
                    stakingSubmissionStatus ===
                      StakingSubmissionStatus.PENDING) && (
                    <ButtonProgressPlacer>
                      <ButtonCircularProgress size={24} />
                    </ButtonProgressPlacer>
                  )}
                  {stakingSubmissionStatus ===
                    StakingSubmissionStatus.PROMPTING && 'Pending...'}
                  {stakingSubmissionStatus ===
                    StakingSubmissionStatus.PENDING && 'Confirming...'}
                  {stakingSubmissionStatus !==
                    StakingSubmissionStatus.PROMPTING &&
                    stakingSubmissionStatus !==
                      StakingSubmissionStatus.PENDING &&
                    'Restake'}
                </ButtonInner>
              </StakeWidgetButton>
            )}
            {account && isZap() && (
              <>
                {!srcToken && (
                  <StakeWidgetButton onClick={() => setTokenSelectOpen(true)}>
                    Select Token
                  </StakeWidgetButton>
                )}
                {srcToken && showApprove() && (
                  <StakeWidgetButton
                    disabled={approveDisabled()}
                    onClick={handleApproveClick}
                  >
                    <ButtonInner>
                      {(approve.status === approve.STATUS.PROMPTING ||
                        approve.status === approve.STATUS.PENDING) && (
                        <ButtonProgressPlacer>
                          <ButtonCircularProgress size={24} />
                        </ButtonProgressPlacer>
                      )}
                      {approve.status === approve.STATUS.PROMPTING &&
                        'Pending...'}
                      {approve.status === approve.STATUS.PENDING &&
                        'Confirming...'}
                      {approve.status !== approve.STATUS.PROMPTING &&
                        approve.status !== approve.STATUS.PENDING &&
                        'Approve'}
                    </ButtonInner>
                  </StakeWidgetButton>
                )}
                {srcToken && !showApprove() && (
                  <StakeWidgetButton
                    disabled={zapDisabled()}
                    onClick={handleZapClick}
                  >
                    {zapRouteLoading() && <ButtonCircularProgress size={35} />}
                    {!zapRouteLoading() && (
                      <ButtonInner>
                        {(zapBuilding ||
                          zap.status === zap.STATUS.PROMPTING ||
                          zap.status === zap.STATUS.PENDING) && (
                          <ButtonProgressPlacer>
                            <ButtonCircularProgress size={24} />
                          </ButtonProgressPlacer>
                        )}
                        {zapBuilding && 'Building...'}
                        {!zapBuilding &&
                          zap.status === zap.STATUS.PROMPTING &&
                          'Pending...'}
                        {zap.status === zap.STATUS.PENDING && 'Confirming...'}
                        {!zapBuilding &&
                          zap.status !== zap.STATUS.PROMPTING &&
                          zap.status !== zap.STATUS.PENDING &&
                          'Zap'}
                      </ButtonInner>
                    )}
                  </StakeWidgetButton>
                )}
              </>
            )}
            {!account && (
              <StakeConnectButton>Connect wallet</StakeConnectButton>
            )}
          </div>
          <StakingConfirmationProgressWidget
            complete={transactionComplete()}
            confirming={transactionConfirming()}
            message={toastMessage()}
            onClose={onToastClose}
            pending={transactionPending()}
            txHash={toastTxHash()}
            open={toastOpen()}
            title={toastTitle()}
          />
          <div>
            <SwellStatistics restake={true} />
          </div>
        </>
      )}
      {action === ACTIONS.VAULT && <VaultView />}
    </StakingWidgetBox>
  )
}

export { RestakingWidget }
