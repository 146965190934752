import React, { useState } from 'react'
import styled from 'styled-components/macro'

interface PillSwitchChoice {
  key: string
  label: string
}

interface PillSwitchConfig {
  choices: PillSwitchChoice[]
  defaultValue: string
}

interface PillSwitchProps {
  config: PillSwitchConfig
  onChange: (key: string) => any
}

interface PillSwitchButtonProps {
  key: string
  selected: boolean
  onClick: () => any
}

const PillSwitchContainer = styled.div`
  display: flex;
  align-items: center;
`

const PillSwitchButton = styled.div<PillSwitchButtonProps>`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  line-height: 24px;
  font-size: 12px;
  padding: 4px 6px;
  border: 1px solid ${({ theme }) => theme.colors.lightBlue['50']};
  border-left: none;
  border-right: none;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:active {
    opacity: 0.4;
  }

  &:first-child {
    border-top-left-radius: 200px;
    border-bottom-left-radius: 200px;
    padding-left: 12px;
    border-left: 1px solid ${({ theme }) => theme.colors.lightBlue['50']};
  }

  &:last-child {
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
    padding-right: 12px;
    border-right: 1px solid ${({ theme }) => theme.colors.lightBlue['50']};
  }

  ${({ theme, selected }) =>
    selected &&
    `
    background-color: ${theme.colors.lightBlue['50']}};
    color ${theme.colors.white['50']}}
  `}
`

function PillSwitch(props: PillSwitchProps) {
  const [value, setValue] = useState<string>(props.config.defaultValue)

  const handleClick = (key: string) => {
    setValue(key)
    props.onChange(key)
  }

  return (
    <PillSwitchContainer>
      {props.config.choices.map((choice: PillSwitchChoice) => {
        return (
          <PillSwitchButton
            key={choice.key}
            selected={choice.key === value}
            onClick={() => handleClick(choice.key)}
          >
            {choice.label}
          </PillSwitchButton>
        )
      })}
    </PillSwitchContainer>
  )
}

export { PillSwitch }
