import styled from 'styled-components'
import { FlexRow } from '@swell-ui/FlexRow'
import { useTimeCountdown } from '@/hooks/useTimeCountdown'

const CountdownItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.7px;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      gap: 7.547px;
    }
  `}
`

const NumberContainer = styled.div`
  width: 57px;
  padding: 13.661px 0;
  border-radius: 7.051px;
  background: #fff;
  text-align: center;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      width: 82px;
      padding: 19.497px 0;
    }
  `}
`

const MainText = styled.div`
  color: #fff;
  text-align: center;
  font-size: 35.254px;
  font-style: normal;
  font-weight: 600;
  line-height: 85%;
  letter-spacing: -1.058px;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      font-size: 50.314px;
      letter-spacing: -1.509px;
    }
  `}
`

const MainTextContrast = styled.div`
  color: #0c0827;
  text-align: center;
  font-size: 35.254px;
  font-style: normal;
  font-weight: 600;
  line-height: 85%;
  letter-spacing: -1.058px;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      font-size: 50.314px;
      letter-spacing: -1.509px;
    }
  `}
`

const ColonContainer = styled.div`
  display: flex;
  width: 11.017px;
  height: 57.14px;
  flex-direction: column;
  justify-content: center;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      width: 15.723px;
      height: 81.551px;
    }
  `}
`

const Label = styled.div`
  color: #fff;
  font-size: 10px;
  line-height: 110%;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const TARGET_DATE_STRING = '2024-05-08T02:00:00Z' // May 8, 2 AM UTC

export function BannerCountdownClock() {
  const countDownStats = useTimeCountdown(TARGET_DATE_STRING)!

  const padWithZero = (value: number) => {
    return value < 10 ? `0${value}` : value
  }

  return (
    <div>
      <FlexRow gap="4.7" align="flex-start">
        <CountdownItem>
          <NumberContainer>
            <MainTextContrast>{countDownStats.days}</MainTextContrast>
          </NumberContainer>
          <Label>Days</Label>
        </CountdownItem>
        <ColonContainer>
          <MainText>:</MainText>
        </ColonContainer>
        <CountdownItem>
          <NumberContainer>
            <MainTextContrast>
              {padWithZero(countDownStats.hours)}
            </MainTextContrast>
          </NumberContainer>
          <Label>Hours</Label>
        </CountdownItem>
        <ColonContainer>
          <MainText>:</MainText>
        </ColonContainer>
        <CountdownItem>
          <NumberContainer>
            <MainTextContrast>
              {padWithZero(countDownStats.minutes)}
            </MainTextContrast>
          </NumberContainer>
          <Label>Minutes</Label>
        </CountdownItem>
        <ColonContainer>
          <MainText>:</MainText>
        </ColonContainer>
        <CountdownItem>
          <NumberContainer>
            <MainTextContrast>
              {padWithZero(countDownStats.seconds)}
            </MainTextContrast>
          </NumberContainer>
          <Label>Seconds</Label>
        </CountdownItem>
      </FlexRow>
    </div>
  )
}
