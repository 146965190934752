import React from 'react'
import { SwethExchangeInfo } from './SwethExchangeInfo'
import { RswethExchangeInfo } from './RswethExchangeInfo'

export function ExchangeInfo({
  restake,
  unstake,
}: {
  restake?: boolean
  unstake?: boolean
}) {
  if (restake) {
    return <RswethExchangeInfo />
  }

  return <SwethExchangeInfo unstake={unstake} />
}
