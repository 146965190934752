import { useCallback } from 'react'
import { useSwellWeb3 } from '../core'

export function useLoadOkxMobileWallet() {
  const { connect } = useSwellWeb3()

  const ua = navigator.userAgent
  const isOKApp = /OKApp/i.test(ua)
  const isInjectedOkx = !!(window as any).okxwallet

  return useCallback(async () => {
    if (!isOKApp) {
      return
    }

    if (!isInjectedOkx) {
      // indicates that the process for connecting has changed
      console.error('okx wallet detected but provider is not present')
      return
    }

    await connect({ autoSelect: { label: 'OKX Mobile', disableModals: true } })
  }, [connect, isOKApp, isInjectedOkx])
}
