import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import * as reducer from './reducer'
import { bindActionCreators } from '@reduxjs/toolkit'
import { IDepositCollectionValidator } from '@/services/DepositCollectionValidator'
import { DepositCollectionValidationResult } from '../depositValidation/models/DepositCollectionValidationResult'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { default: _default, ...actions } = reducer

export const useRestakingDepositCollectionValidationActions = () => {
  const dispatch = useAppDispatch()

  return useMemo(() => {
    return bindActionCreators(actions, dispatch)
  }, [dispatch])
}

export const useRestakingDepositCollectionValidationState = () => {
  return useAppSelector((state) => state.restakingDepositValidation)
}

/**
 * Binds changes to the deposit data input field to the store
 *
 * Provides an interface which is similar to React.useState
 */
export const useRestakingDepositCollectionValidationInput = () => {
  const input = useRestakingDepositCollectionValidationState().input

  const setInput =
    useRestakingDepositCollectionValidationActions().setDepositCollectionInput

  return [input, setInput] as const
}

/**
 * Provides a callback for validating the user's input, given an instance of the deposit
 *  data validator service.
 *
 * @param depositCollectionValidator Instance of the DepositCollectionValidator service
 * @returns Callback for validating the current user input (kept in the store)
 */
export const useValidateRestakingDepositCollection = (
  depositCollectionValidator: IDepositCollectionValidator
) => {
  const input = useAppSelector(
    (state) => state.restakingDepositValidation.input
  )

  const { setValidating, setValidationProgress, setValidationResult } =
    useRestakingDepositCollectionValidationActions()

  const validate = useCallback(async () => {
    const validateInput = async () => {
      const processInputResult = depositCollectionValidator.processInput(input)

      if (processInputResult.problemCode) {
        setValidationResult(
          DepositCollectionValidationResult.fromInputProblem({
            code: processInputResult.problemCode as any,
          })
        )
        return
      }

      const { depositCollection } = processInputResult as Required<
        typeof processInputResult
      >

      try {
        const result = await depositCollectionValidator.validateAll({
          depositCollection,
          setValidationProgress,
        })

        setValidationResult(result)
      } catch (err) {
        setValidationResult(
          DepositCollectionValidationResult.fromInputProblem({
            code: 'something-went-wrong',
          })
        )
      }
    }

    const startTime = Date.now()

    setValidating(true)
    try {
      await validateInput()
    } finally {
      setValidating(false)

      const endTime = Date.now()
      const timeElapsed = endTime - startTime
      console.info('Validation complete', { timeElapsed })
    }
  }, [
    depositCollectionValidator,
    input,
    setValidating,
    setValidationProgress,
    setValidationResult,
  ])

  return validate
}
