import React from 'react'
import { useDepositCollectionValidationInput } from '@/state/depositValidation/hooks'
import useStakingDepositCollectionValidation from '@/hooks/useStakingDepositCollectionValidation'
import { DepositInfo } from '@/types/deposits'
import { UploadDepositCollectionCommon } from './UploadDepositCollectionCommon'

interface StakingUploadDepositCollectionWidgetProps {
  onSubmit: (chunks: DepositInfo[][]) => void
  preventInteraction: boolean
  setTextAreaRef: any
}

export function StakingUploadDepositCollectionWidget(
  props: StakingUploadDepositCollectionWidgetProps
) {
  const depositCollectionValidationInput = useDepositCollectionValidationInput()
  const depositCollectionValidation = useStakingDepositCollectionValidation()

  return (
    <UploadDepositCollectionCommon
      depositCollectionValidationInput={depositCollectionValidationInput}
      depositCollectionValidation={depositCollectionValidation}
      {...props}
    />
  )
}
