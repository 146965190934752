import { TOKEN_LIST_ETH } from '@/constants/tokens'
import { ZapRoute } from '@/state/zap/types'
import { Token } from '@/types/tokens'
import { formatUnits } from 'ethers/lib/utils'
import { useEffect } from 'react'

// set dest token input value if src token input value is changed
export function useZapInputBehaviour({
  route,
  srcToken,
  setDestTokenInputValue,
  rswethDecimals,
  paused,
}: {
  srcToken: Token
  route: ZapRoute | undefined
  setDestTokenInputValue: (value: string) => void
  rswethDecimals: number
  paused: boolean
}) {
  useEffect(() => {
    if (paused) return
    if (!route) return
    if (srcToken.symbol === TOKEN_LIST_ETH.symbol) return
    const { toAmount } = route
    setDestTokenInputValue(formatUnits(toAmount, rswethDecimals))
  }, [route, srcToken, setDestTokenInputValue, rswethDecimals, paused])
}
