import React from 'react'

function WstethIcon(props: any) {
  return (
    <svg
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11Z"
        fill="#00A3FF"
      />
      <path
        opacity="0.6"
        d="M15.5123 10.2185L15.6354 10.4074C17.0244 12.5382 16.7142 15.3288 14.8896 17.1165C13.8162 18.1683 12.4093 18.6943 11.0024 18.6944C11.0024 18.6944 11.0024 18.6944 15.5123 10.2185Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M11.0019 12.7946L15.5117 10.2185C11.0019 18.6944 11.0019 18.6944 11.0019 18.6944C11.0019 16.8484 11.0019 14.7331 11.0019 12.7946Z"
        fill="white"
      />
      <path
        d="M6.48777 10.2185L6.36462 10.4074C4.97561 12.5382 5.28585 15.3288 7.11047 17.1165C8.18388 18.1683 9.59072 18.6943 10.9976 18.6944C10.9976 18.6944 10.9976 18.6944 6.48777 10.2185Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M10.9963 12.7946L6.48645 10.2185C10.9963 18.6944 10.9963 18.6944 10.9963 18.6944C10.9963 16.8484 10.9963 14.7331 10.9963 12.7946Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M11.0035 7.03967V11.4826L14.8883 9.26258L11.0035 7.03967Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M11.0024 7.03967L7.11487 9.26253L11.0024 11.4826V7.03967Z"
        fill="white"
      />
      <path
        d="M11.0024 3.30322L7.11487 9.26402L11.0024 7.03493V3.30322Z"
        fill="white"
      />
      <path
        opacity="0.6"
        d="M11.0035 7.03429L14.8912 9.26346L11.0035 3.29968V7.03429Z"
        fill="white"
      />
    </svg>
  )
}

export { WstethIcon }
