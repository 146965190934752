import React from 'react'
import styled from 'styled-components/macro'
import { BigNumber } from 'ethers'
import { useSwellWeb3 } from '@swell-web3/core'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { FlexRow } from '@/swell-ui/FlexRow'
import { Grid } from '@swell-ui/Grid'
import { Typography } from '@swell-ui/Typography'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { SwethIcon } from '@swell-ui/icons/SwethIcon'
import { RswethIcon } from '@swell-ui/icons/RswethIcon'
import { UsdIcon } from '@swell-ui/icons/UsdIcon'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { useChainInfo } from '@/state/deployments/hooks'
import { useStakingRate } from '@/state/stakingStats/hooks'
import { useRestakingRate } from '@/state/restakingStats/hooks'
import { useSwEthBalance, useRswEthBalance } from '@/state/user/hooks'
import {
  useSwEthUsdMarketRate,
  useRswEthUsdMarketRate,
} from '@/state/fiat/hooks'
import {
  useDisplaySwEth,
  useDisplaySwEthInNativeCurrency,
  useDisplaySwEthPriceFiat,
} from '@/hooks/useSwEthDisplay'
import {
  useDisplayRswEth,
  useDisplayRswEthInNativeCurrency,
  useDisplayRswEthPriceFiat,
} from '@/hooks/useRswEthDisplay'
import { useVoyageUserV3Backend } from '@/services/V3BackendService/hooks'
import { displayPearls } from '@/util/displayNumbers'
import pearlUrl from '@/assets/svg/voyage/pearl.svg'
import { useDisplayNativeCurrency } from '@/hooks/useNativeCurrencyDisplay'
import { applyBigNumberRateBN } from '@/util/big'

const HoldingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  > div:not(:last-child) {
    padding-bottom: 24px;
  }
`

const InnerBox = styled(FlexRow)`
  padding: 24px;
  border-radius: 8px;
  background: rgba(11, 20, 27, 0.5);
  color: ${({ theme }) => theme.colors.white['50']};
`

const SwEthInnerBox = styled(InnerBox)`
  background: var(
    --gradient,
    linear-gradient(180deg, #3068ef 0.29%, #1322ac 167.95%)
  );
`

const RswEthInnerBox = styled(InnerBox)`
  background: linear-gradient(0deg, #cedffc 35.46%, #ecf2fe 126.53%);
  color: ${({ theme }) => theme.colors.federalBlue['150']};
`

const RewardsBalanceInnerBox = styled(InnerBox)`
  border: 1px solid ${({ theme }) => theme.colors.auxGrey};
`

const ConnectZone = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
  border-radius: 16px;

  > div:first-child {
    padding-bottom: 24px;
  }

  background: rgba(11, 20, 27, 0.5);
  color: ${({ theme }) => theme.colors.white['150']};
`

const StyledSwethIcon = styled(SwethIcon)`
  height: 28px;
  width: 28px;
  position: relative;
  top: 6px;
  margin-left: 12px;
  margin-right: 4px;
`

const StyledRswethIcon = styled(RswethIcon)`
  height: 28px;
  width: 28px;
  position: relative;
  top: 6px;
  margin-left: 12px;
  margin-right: 4px;
`

const StyledEthIcon = styled(EthIcon)`
  height: 20px;
  width: 20px;
  position: relative;
  top: 4px;
  margin-left: 9.38px;
  margin-right: 5.38px;
`

const StyledUsdIcon = styled(UsdIcon)`
  height: 19.25px;
  width: 19.25px;
  position: relative;
  top: 4px;
  margin-left: 9.38px;
  margin-right: 5.38px;
`

const Pearl = styled.img`
  width: 28px;
  height: 28px;
`

function SwEthConversionBox({
  swEthAmount,
}: {
  swEthAmount: BigNumber | undefined
}) {
  const { nativeCurrency } = useChainInfo()
  const stakingRate = useStakingRate()
  const swEthUsdMarketRate = useSwEthUsdMarketRate()

  const displaySwEth = useDisplaySwEth()
  const displaySwEthPriceFiat = useDisplaySwEthPriceFiat()
  const displayNativeCurrency = useDisplayNativeCurrency()

  return (
    <SwEthInnerBox direction="column" align="flex-start" gap="12">
      <Typography variant="body" size="small" letterSpacing="small">
        swETH balance
      </Typography>
      <Typography variant="body" size="large" letterSpacing="small">
        {swEthAmount &&
          displaySwEth(swEthAmount as BigNumber, {
            precision: 4,
            localize: true,
            numberOnly: true,
          })}
        <StyledSwethIcon />
        swETH
      </Typography>
      <Typography variant="body" size="medium" letterSpacing="small">
        =&nbsp;
        {swEthAmount &&
          stakingRate &&
          stakingRate.data &&
          displayNativeCurrency(
            applyBigNumberRateBN(swEthAmount, stakingRate.data.swETHToETHRate),
            { precision: 4, localize: true, numberOnly: true }
          )}
        <StyledEthIcon />
        {nativeCurrency.symbol}
      </Typography>
      <Typography variant="body" size="medium" letterSpacing="small">
        =&nbsp;
        {swEthAmount &&
          swEthUsdMarketRate &&
          swEthUsdMarketRate.data &&
          displaySwEthPriceFiat(swEthAmount, swEthUsdMarketRate.data.rate)}
        <StyledUsdIcon />
        USD
      </Typography>
    </SwEthInnerBox>
  )
}

function RswEthConversionBox({
  rswEthAmount,
}: {
  rswEthAmount: BigNumber | undefined
}) {
  const { nativeCurrency } = useChainInfo()
  const restakingRate = useRestakingRate()
  const rswEthUsdMarketRate = useRswEthUsdMarketRate()

  const displayRswEth = useDisplayRswEth()
  const displayRswEthPriceFiat = useDisplayRswEthPriceFiat()
  const displayNativeCurrency = useDisplayNativeCurrency()

  return (
    <RswEthInnerBox direction="column" align="flex-start" gap="12">
      <Typography variant="body" size="small" letterSpacing="small">
        rswETH balance
      </Typography>
      <Typography variant="body" size="large" letterSpacing="small">
        {rswEthAmount &&
          displayRswEth(rswEthAmount as BigNumber, {
            precision: 4,
            localize: true,
            numberOnly: true,
          })}
        <StyledRswethIcon />
        rswETH
      </Typography>
      <Typography variant="body" size="medium" letterSpacing="small">
        =&nbsp;
        {rswEthAmount &&
          restakingRate &&
          restakingRate.data &&
          displayNativeCurrency(
            applyBigNumberRateBN(
              rswEthAmount,
              restakingRate.data.rswETHToETHRate
            ),
            { precision: 4, localize: true, numberOnly: true }
          )}
        <StyledEthIcon />
        {nativeCurrency.symbol}
      </Typography>
      <Typography variant="body" size="medium" letterSpacing="small">
        =&nbsp;
        {rswEthAmount &&
          rswEthUsdMarketRate &&
          rswEthUsdMarketRate.data &&
          displayRswEthPriceFiat(rswEthAmount, rswEthUsdMarketRate.data.rate)}
        <StyledUsdIcon />
        USD
      </Typography>
    </RswEthInnerBox>
  )
}

function RewardsBalanceBox() {
  const { account } = useSwellWeb3()

  const voyageUserQuery = useVoyageUserV3Backend(account)

  return (
    <RewardsBalanceInnerBox>
      <FlexRow direction="column" align="flex-start" gap="12">
        <Typography variant="body" size="small" letterSpacing="small">
          Rewards balance
        </Typography>
        <FlexRow gap="12">
          <FlexRow gap="12">
            <Pearl src={pearlUrl} />
            <Typography variant="body" size="large" letterSpacing="small">
              <AsyncDiv loading={!voyageUserQuery.data}>
                {() => {
                  return displayPearls(voyageUserQuery.data?.points ?? 0)
                }}
              </AsyncDiv>
            </Typography>
          </FlexRow>
        </FlexRow>
      </FlexRow>
    </RewardsBalanceInnerBox>
  )
}

function SwEthHoldings() {
  const { account } = useSwellWeb3()
  const swEthBalance = useSwEthBalance()
  const rswEthBalance = useRswEthBalance()

  return (
    <HoldingsWrapper>
      <Typography variant="body" size="large" letterSpacing="small">
        Holdings
      </Typography>
      {account && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SwEthConversionBox swEthAmount={swEthBalance.data?.balance} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RswEthConversionBox rswEthAmount={rswEthBalance.data?.balance} />
          </Grid>
          `
          <Grid item xs={12}>
            <RewardsBalanceBox />
          </Grid>
        </Grid>
      )}
      {!account && (
        <ConnectZone>
          <div>Connect wallet to see available data.</div>
          <div>
            <ConnectWalletButton variant="secondary" size="small">
              Connect wallet
            </ConnectWalletButton>
          </div>
        </ConnectZone>
      )}
    </HoldingsWrapper>
  )
}

export { SwEthHoldings }
