import { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button } from '@swell-ui/Button'
import { CircularProgress } from '@swell-ui/CircularProgress'
import { Divider } from '@swell-ui/Divider'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { ExternalLinkIcon } from '@swell-ui/icons/ExternalLinkIcon'
import { FlexRow } from '@swell-ui/FlexRow'
import { Popper } from '@swell-ui/Popper'
import { SwethIcon } from '@swell-ui/icons/SwethIcon'
import { Typography } from '@swell-ui/Typography'
import { StakingConfirmationProgressWidget } from '@/components/StakingConfirmationProgressWidget'
import { useFinalizeWithdrawal } from '@/hooks/useFinalizeWithdrawal'
import { useClaims } from '@/state/user/hooks'
import { formatWithConfig } from '@/util/number'
import { Claim, ClaimSummary } from '@/types/claims'

const ClaimViewContainer = styled(FlexRow)`
  padding-top: 16px;
`

const BorderedFlex = styled<any>(FlexRow)`
  position: relative;
  padding: 10px 12px;
  border: 1px solid ${({ theme }) => theme.colors.auxGrey};
  border-radius: 8px;
  align-self: stretch;
`

const ClaimRowsFlex = styled<any>(FlexRow)`
  height: 331px;

  ${({ numRows }) => numRows > 6 && `overflow-y: scroll;`}
`

const Grow = styled.div`
  flex-grow: 1;
`

const GreenDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: rgba(26, 255, 75, 1);
`

const YellowDot = styled(GreenDot)`
  background: ${({ theme }) => theme.colors.eigenLayer['yellow']};
`

const StyledEthIcon = styled(EthIcon)`
  width: 16px;
  height: 16px;
`

const StyledSwethIcon = styled(SwethIcon)`
  width: 16px;
  height: 16px;
`

const ExternalA = styled.a`
  display: flex;
`

const StyledLinkIcon = styled(ExternalLinkIcon)`
  height: 16px;
  width: 19px;

  &:hover {
    opacity: 0.7;
  }
`

const Status = styled.div`
  text-transform: capitalize;
`

const StyledPopper = styled(Popper)`
  z-index: 50;
`

const ClaimTooltip = styled.div`
  position: relative;
  left: 30px;
  top: 8px;
`

const TooltipText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 18px 0 0 11px;
  z-index: 2;
`

const TooltipLabel = styled.span`
  color: ${({ theme }) => theme.colors.white['125']};
`

const Circle = styled.div`
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid ${({ theme }) => theme.colors.white['50']};
  border-radius: 50%;

  &:hover {
    cursor: pointer;
  }
`

const InnerCircle = styled(Circle)`
  position: absolute;
  top: 1.5px;
  left: 1.5px;
  width: 7px;
  height: 7px;
  border: none;
  background: ${({ theme }) => theme.colors.white['50']};
`

const LoadingContainer = styled.div`
  height: 446px;
  margin: 0 auto;
`

function formatNumber(amount: number) {
  return formatWithConfig(amount, {
    localize: true,
    precision: 4,
  })
}

// formatClaimDate formats a unix timestamp to the form "2023.12.25 12:12"
function formatClaimDate(ts: number) {
  const d = new Date(ts * 1000)
  const year = d.getFullYear()
  const month = d.getMonth() + 1
  const day = d.getDate()
  const hours = String(d.getHours()).padStart(2, '0')
  const minutes = String(d.getMinutes()).padStart(2, '0')
  return `${year}.${month}.${day} ${hours}:${minutes}`
}

const isPending = (claim: Claim): boolean => {
  return claim.status.toLowerCase() === 'pending'
}

const isClaimable = (claim: Claim): boolean => {
  return claim.status.toLowerCase() === 'claimable'
}

function ClaimRow({
  claim,
  onClaimSelect,
  selected,
}: {
  claim: Claim
  onClaimSelect: () => any
  selected: boolean
}) {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [selectedClaim, setSelectedClaim] = useState<any>(undefined)
  const anchorRef = useRef<HTMLElement | null>(null)

  return (
    <>
      <BorderedFlex ref={anchorRef}>
        <Grow>
          <FlexRow gap="8">
            {isPending(claim) && (
              <>
                <StyledSwethIcon />
                <div>{formatNumber(claim.swethAmount)} swETH</div>
              </>
            )}
            {isClaimable(claim) && (
              <>
                <StyledEthIcon />
                <div>{formatNumber(claim.ethAmount)} ETH</div>
              </>
            )}
          </FlexRow>
        </Grow>
        <div>
          <FlexRow gap="9">
            {isClaimable(claim) && (
              <div>
                <Circle onClick={onClaimSelect}>
                  {selected && <InnerCircle />}
                </Circle>
              </div>
            )}
            <FlexRow gap="8">
              {isPending(claim) ? <YellowDot /> : <GreenDot />}
              <Status
                onMouseEnter={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
              >
                {claim.status}
              </Status>
            </FlexRow>
            <ExternalA
              href={`https://etherscan.io/nft/0x48c11b86807627af70a34662d4865cf854251663/${claim.tokenId}`}
              target="_blank"
              rel="noreferrer"
            >
              <StyledLinkIcon />
            </ExternalA>
          </FlexRow>
        </div>
      </BorderedFlex>
      {isPending(claim) && showTooltip && (
        <StyledPopper
          id={claim.tokenId}
          open={true}
          anchorEl={anchorRef.current}
        >
          <ClaimTooltip>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="240"
              height="117"
              viewBox="0 0 240 117"
              fill="none"
            >
              <path
                d="M1 112V11.4919C1 9.2828 2.79086 7.49194 4.99999 7.49194H195.869C196.93 7.49194 197.948 7.06994 198.699 6.3189L203.18 1.83281C204.742 0.268768 207.277 0.268769 208.84 1.83282L213.321 6.3189C214.071 7.06994 215.09 7.49194 216.151 7.49194H235C237.209 7.49194 239 9.2828 239 11.4919V112C239 114.209 237.209 116 235 116H5C2.79086 116 1 114.209 1 112Z"
                fill="#051A2B"
                stroke="url(#paint0_linear_6016_94618)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_6016_94618"
                  x1="120"
                  y1="-1"
                  x2="119.436"
                  y2="196.299"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#3068EF" />
                  <stop offset="1" stopColor="#1322AC" />
                </linearGradient>
              </defs>
            </svg>
            <TooltipText>
              <Typography variant="body" size="small" letterSpacing="small">
                <div>
                  <TooltipLabel>Queue position:</TooltipLabel>&nbsp;
                  {claim.queuePosition} of {claim.totalInQueue}
                </div>
                <div>
                  <TooltipLabel>Request timestamp:</TooltipLabel>&nbsp;
                  {formatClaimDate(claim.requestTimestamp)}
                </div>
                <div>
                  <TooltipLabel>swETH-ETH rate:</TooltipLabel>&nbsp;
                  {formatNumber(claim.exchangeRate)}
                </div>
                <div>
                  <TooltipLabel>ETH:</TooltipLabel>&nbsp;
                  {formatNumber(claim.ethAmount)}
                </div>
              </Typography>
            </TooltipText>
          </ClaimTooltip>
        </StyledPopper>
      )}
    </>
  )
}

export function ClaimView() {
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)

  const claimsQuery = useClaims()
  const claims = claimsQuery.data

  const numClaimable = useMemo<number>(() => {
    if (!claims) {
      return 0
    }
    const claimableClaims = claims.filter((claim: any) => {
      return isClaimable(claim)
    })

    return claimableClaims.length
  }, [claims])

  const claimSummary = useMemo<ClaimSummary>(() => {
    let numClaimable = 0
    let ethAmountClaimable = 0
    let numPending = 0
    let swethAmountPending = 0

    if (claims) {
      claims.forEach((claim: any) => {
        if (isClaimable(claim)) {
          numClaimable++
          ethAmountClaimable += claim.ethAmount
        } else {
          numPending++
          swethAmountPending += claim.swethAmount
        }
      })

      if (numClaimable === 1) {
        setSelectedIndex(0)
      }
    }

    return {
      numClaimable,
      ethAmountClaimable: formatNumber(ethAmountClaimable),
      numPending,
      swethAmountPending: formatNumber(swethAmountPending),
    }
  }, [claims, setSelectedIndex])

  const finalizeWithdrawal = useFinalizeWithdrawal()

  const claimDisabled = (): boolean => {
    if (numClaimable === 0 || (numClaimable >= 1 && selectedIndex === -1))
      return true
    if (finalizeWithdrawal.status !== finalizeWithdrawal.STATUS.IDLE)
      return true
    return false
  }

  const handleClaim = () => {
    if (!claims) {
      return
    }

    const claim = claims[selectedIndex]
    finalizeWithdrawal.sendTransaction(claim)
  }

  const toastTitle = (): string => {
    if (finalizeWithdrawal.status === finalizeWithdrawal.STATUS.PROMPTING) {
      return 'Claim pending'
    } else if (
      finalizeWithdrawal.status === finalizeWithdrawal.STATUS.PENDING
    ) {
      return 'Claim confirming'
    }

    return 'Claim completed'
  }

  const toastMessage = (): string => {
    return `Claiming ${formatNumber(
      finalizeWithdrawal.ethAmount
    )} from unstake.`
  }

  const toastTxHash = (): string => {
    if (finalizeWithdrawal.tx) {
      return finalizeWithdrawal.tx.hash
    }
    return ''
  }

  const onToastClose = () => {
    finalizeWithdrawal.clear()
    setSelectedIndex(-1)
  }

  return (
    <ClaimViewContainer direction="column" align="flex-start" gap="16">
      <Typography variant="body" size="large">
        Claim unstaked swETH
      </Typography>
      {!claimsQuery.isLoading && (
        <>
          <BorderedFlex justify="space-between" align="flex-start">
            <Grow>
              <FlexRow direction="column" align="flex-start" gap="12">
                <FlexRow gap="7">
                  <GreenDot />
                  <Typography
                    variant="body"
                    size="medium"
                    letterSpacing="small"
                  >
                    Claimable
                  </Typography>
                </FlexRow>
                <Typography variant="body" size="small" letterSpacing="small">
                  <FlexRow gap="6">
                    <div>{claimSummary.numClaimable}</div>
                    <div>|</div>
                    <FlexRow gap="3">
                      <StyledEthIcon />
                      <div>{claimSummary.ethAmountClaimable} ETH</div>
                    </FlexRow>
                  </FlexRow>
                </Typography>
              </FlexRow>
            </Grow>
            <div>
              <FlexRow direction="column" align="flex-start" gap="12">
                <FlexRow gap="7">
                  <YellowDot />
                  <Typography
                    variant="body"
                    size="medium"
                    letterSpacing="small"
                  >
                    Pending
                  </Typography>
                </FlexRow>
                <Typography variant="body" size="small" letterSpacing="small">
                  <FlexRow gap="6">
                    <div>{claimSummary.numPending}</div>
                    <div>|</div>
                    <FlexRow gap="3">
                      <StyledSwethIcon />
                      <div>{claimSummary.swethAmountPending} swETH</div>
                    </FlexRow>
                  </FlexRow>
                </Typography>
              </FlexRow>
            </div>
          </BorderedFlex>
          <Divider margin="0" />
          <ClaimRowsFlex
            direction="column"
            gap="12"
            numRows={claims ? claims.length : 0}
          >
            {claims &&
              claims.map((claim: any, index: number) => {
                return (
                  <ClaimRow
                    key={index}
                    claim={claim}
                    onClaimSelect={() => setSelectedIndex(index)}
                    selected={selectedIndex === index}
                  />
                )
              })}
          </ClaimRowsFlex>
          <StakingConfirmationProgressWidget
            complete={
              finalizeWithdrawal.status === finalizeWithdrawal.STATUS.FULFILLED
            }
            confirming={
              finalizeWithdrawal.status === finalizeWithdrawal.STATUS.PROMPTING
            }
            message={toastMessage()}
            onClose={onToastClose}
            pending={
              finalizeWithdrawal.status === finalizeWithdrawal.STATUS.PENDING
            }
            txHash={toastTxHash()}
            open={finalizeWithdrawal.status !== finalizeWithdrawal.STATUS.IDLE}
            title={toastTitle()}
          />
        </>
      )}
      {claimsQuery.isLoading && (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
      <Button
        variant="primary"
        fullWidth
        onClick={handleClaim}
        disabled={claimDisabled()}
      >
        Claim
      </Button>
    </ClaimViewContainer>
  )
}
