export const ACTIONS = {
  CLAIM: 'Claim',
  STAKE: 'Stake',
  SWAP: 'Swap',
  RESTAKE: 'Restake',
  UNSTAKE: 'Unstake',
  VAULT: 'Vault',
  ZAP: 'Zap',
}

export const TRANSACTION_TOAST_TITLE = {
  COMPLETED: 'Transaction completed!',
  STAKE_PROMPTING: 'Stake pending',
  STAKE_PENDING: 'Stake confirming',
  UNSTAKE_PROMPTING: 'Unstake request pending',
  UNSTAKE_PENDING: 'Unstake request confirming',
  UNSTAKE_COMPLETED: 'Unstake request completed!',
  RESTAKE_PROMPTING: 'Restake pending',
  RESTAKE_PENDING: 'Stake confirming',
  APPROVE_PROMPTING: 'Approve pending',
  APPROVE_PENDING: 'Approve confirming',
  ZAP_BUILDING: 'Zap building',
  ZAP_PROMPTING: 'Zap pending',
  ZAP_PENDING: 'Zap confirming',
}
