/**
 * Copied: https://github.com/Uniswap/interface/blob/ad2472eac638b389316ba1f3c3f1ed08fbbb12cd/src/constants/chains.ts
 * Changed:
 * - Changed the set of supported chains (removed many)
 * - Added hardhat chain
 * - Removed distinction between L1 and L2 chains
 * - Moved `isSupportedChain` to src/util/supportedChainId.ts since it is not constant (environment dependent)
 * - Kept only information about chain names
 */

/**
 * List of all the networks supported by Swell Network
 */
export enum SupportedChainId {
  MAINNET = 1,
  GOERLI = 5,
  HARDHAT = 1337,
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.HARDHAT]: 'hardhat',
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.GOERLI]: 'goerli',
}

export const getChainName = (chainId: number) => {
  return CHAIN_IDS_TO_NAMES[chainId as SupportedChainId]
}

export const CHAIN_IDS_TO_TITLE_NAMES = {
  [SupportedChainId.HARDHAT]: 'Hardhat',
  [SupportedChainId.MAINNET]: 'Ethereum Mainnet',
  [SupportedChainId.GOERLI]: 'Goerli Test Network',
}

export const getChainTitleName = (chainId: number) => {
  return CHAIN_IDS_TO_TITLE_NAMES[chainId as SupportedChainId]
}
