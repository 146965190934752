import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { FlexRow } from '@swell-ui/FlexRow'
// import { RswethIcon } from '@swell-ui/icons/RswethIcon'
import { Typography } from '@swell-ui/Typography'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { useSwellUiTheme } from '@swell-ui/theme'
import eigenlayerLogoDarkUrl from '@/assets/images/eigenlayer-logo-dark.png'
import {
  BannerCountdownClock,
  TARGET_DATE_STRING,
} from './BannerCountdownClock'
import SwellL2Logo from '../Logo/SwellL2Logo'
import { Link } from 'react-router-dom'

const BannerContainer = styled.div`
  position: relative;
  background: #0c0827;
  border-radius: 16px;
  padding: 24px 24px;
  color: #fff;
  text-align: center;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      text-align: left;
      padding-right: 40px;
      margin: 0 auto;
    }
  `}
`

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 8px;
  right: 10px;

  path {
    stroke: #fff;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`

const EigenlayerLogo = styled.img`
  width: 71.778px;
  height: 56px;

  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      width: 82.032px;
      height: 64px;
    }
  `}
`

const BannerTypography = styled(Typography)`
  ${({ theme }) => `
    ${theme.breakpoints.up('md')} {
      font-size: 18px;
      line-height: 18px;
      letter-spacing: -0.342px;
      line-height: 150%;
    }
  `}
`

const BannerSectionTitle = styled.div`
  font-weight: 700;
`

const BannerAnchor = styled(Link)`
  color: #fff;
  text-decoration-line: underline !important;
`

// const StyledRswethIcon = styled(RswethIcon)`
//   height: 96px;
//   width: 96px;
// `

const REVEAL_DATE_STRING = '2024-04-09T00:00:00Z' // April 9
const HIDE_BANNER_DATE_STRING = TARGET_DATE_STRING

export function AppBanner() {
  const { theme } = useSwellUiTheme()
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'))

  const [now, setNow] = useState(Date.now())

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now())
    }, 1000)

    return () => clearInterval(interval)
  })

  const [open, setOpen] = useState<boolean>(true)

  const handleClose = () => {
    setOpen(false)
  }

  let bannerDirection = 'column'
  let bannerGap = '12'

  if (isLgUp) {
    bannerDirection = 'row'
    bannerGap = '24'
  }

  if (!open) {
    return null
  }

  if (now <= new Date(REVEAL_DATE_STRING).getTime()) {
    return null
  }

  if (now >= new Date(HIDE_BANNER_DATE_STRING).getTime()) {
    return null
  }

  return (
    <BannerContainer>
      <StyledCloseIcon onClick={handleClose} />
      <FlexRow direction={bannerDirection} gap={bannerGap}>
        <SwellL2Logo />
        <BannerTypography variant="body" size="xsmall" letterSpacing="small">
          1 Million EigenLayer points will be given away to Swell L2 depositors
          that deposit in the first 4 weeks.&nbsp;
          <BannerAnchor to="/swell-l2">Don't miss out</BannerAnchor>
        </BannerTypography>
        <BannerCountdownClock />
      </FlexRow>
    </BannerContainer>
  )
}
