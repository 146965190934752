import {
  useGetPreDepositRatesUsdV3Backend,
  useGetPreDepositStatsV3Backend,
  usePreDepositTokenListV3Backend,
  usePreDepositTokenSupportedStatesV3Backend,
} from '@/services/V3BackendService/hooks'
import { isEthAddressAlias } from '@/util/tokens'
import { uniq } from 'lodash'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useTokenBalancesMulticall } from '@/hooks/erc20/useTokenBalancesMulticall'
import { useTokenAllowancesMulticall } from '@/hooks/erc20/useTokenAllowancesMulticall'
import { BigNumber, ethers } from 'ethers'
import { Multicall3, SimpleStakingERC20 } from '@/abis/types'
import {
  useMulticallContract,
  usePreDepositStakingContractView,
} from '@/hooks/useContract'
import useSWRImmutable from 'swr/immutable'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import { useEthBalance } from '@/state/user/hooks'
import { TokenList } from '@/types/tokens'
import { ETH_ALIAS, NEW_PREDEPOSIT_TOKENS } from '../constants'
import { defaultTokenList } from '../defaultTokenList'
import { useMemo } from 'react'

export const usePreDepositTokenSupportedStates =
  usePreDepositTokenSupportedStatesV3Backend
export const useGetPreDepositStats = useGetPreDepositStatsV3Backend
export const useGetPreDepositRatesUsd = useGetPreDepositRatesUsdV3Backend

function useDefaultPreDepositTokenList() {
  const { addresses } = useDeploymentSetConfig()
  return useMemo(() => {
    const tl = defaultTokenList(addresses)
    // check validity and warn
    const addrsSet = new Set<string>()
    for (const token of tl.tokens) {
      if (addrsSet.has(token.address)) {
        console.error(
          'duplicate token address in default token list',
          token.address
        )
      }
      addrsSet.add(token.address)
    }
    return tl
  }, [addresses])
}

function usePreDepositTokenListWithDefaults() {
  let isFetching = false
  let data: TokenList | undefined = undefined

  const defaultData = useDefaultPreDepositTokenList()
  const tokenListQuery = usePreDepositTokenListV3Backend()

  if (tokenListQuery.data?.tokenList) {
    data = tokenListQuery.data.tokenList
  }
  if (!data) {
    data = defaultData
    isFetching = true
  }

  if (!data.tokens.length) {
    // recover from problems
    console.error('fetched token list has no tokens, using default')
    data = defaultData
    isFetching = false
  }

  return { data /* never null */, isFetching }
}

function usePreDepositTokenListHardcoded() {
  const data = useDefaultPreDepositTokenList()
  return { data, isFetching: false }
}
export { usePreDepositTokenListHardcoded as usePreDepositTokenList }

function useNewPreDepositTokensHardcoded() {
  return {
    data: Array.from(NEW_PREDEPOSIT_TOKENS),
    isFetching: false,
  }
}

export { useNewPreDepositTokensHardcoded as useNewPreDepositTokens }

export function usePreDepositTokenBalances(tokenAddresses: string[]) {
  const { account } = useSwellWeb3()
  const ethBalanceQuery = useEthBalance()

  const nonAliasAddresses = tokenAddresses.filter(
    (address) => !isEthAddressAlias(address)
  )
  const requestedEth = nonAliasAddresses.length !== tokenAddresses.length
  const balancesQuery = useTokenBalancesMulticall(nonAliasAddresses)

  return {
    isLoading: balancesQuery.isLoading || ethBalanceQuery.isLoading,
    mutate: () => {
      balancesQuery.mutate()
      ethBalanceQuery.mutate()
    },
    get data() {
      if (!account || !balancesQuery.data || !ethBalanceQuery.data) {
        return undefined
      }

      const ethBalance = ethBalanceQuery.data.balance
      const balances = balancesQuery.data

      if (requestedEth) {
        ;(balances as any)[ETH_ALIAS] = ethBalance
      }

      return {
        balances,
      }
    },
  }
}

export function useTokenAllowancesForStaking(tokenAddresses: string[]) {
  const { account: owner } = useSwellWeb3()

  const {
    addresses: { preDepositStaking: spender },
  } = useDeploymentSetConfig()

  const nonAliasAddresses = tokenAddresses.filter(
    (address) => !isEthAddressAlias(address)
  )
  const requestedEth = nonAliasAddresses.length !== tokenAddresses.length
  const allowancesQuery = useTokenAllowancesMulticall(
    nonAliasAddresses,
    spender
  )

  return {
    ...allowancesQuery,
    get data() {
      if (!owner || !allowancesQuery.data) {
        return undefined
      }
      const allowances = allowancesQuery.data

      if (requestedEth) {
        ;(allowances as any)[ETH_ALIAS] = ethers.constants.MaxUint256
      }

      return {
        allowances,
      }
    },
  }
}

export function useTokenAllowancesForZap(tokenAddresses: string[]) {
  const { account: owner } = useSwellWeb3()

  const {
    addresses: { preDepositZap: spender },
  } = useDeploymentSetConfig()

  const nonAliasAddresses = tokenAddresses.filter(
    (address) => !isEthAddressAlias(address)
  )
  const requestedEth = nonAliasAddresses.length !== tokenAddresses.length
  const allowancesQuery = useTokenAllowancesMulticall(
    nonAliasAddresses,
    spender
  )

  return {
    ...allowancesQuery,
    get data() {
      if (!owner || !allowancesQuery.data) {
        return undefined
      }
      const allowances = allowancesQuery.data

      if (requestedEth) {
        ;(allowances as any)[ETH_ALIAS] = ethers.constants.MaxUint256
      }

      return {
        allowances,
      }
    },
  }
}

async function fetchPreDepositStakes(
  stakingContract: SimpleStakingERC20,
  multicall: Multicall3,
  tokenContracts: string[],
  account: string
) {
  const indexToTokenContract = new Map<number, string>()
  const calls: Multicall3.CallStruct[] = []
  tokenContracts.forEach((tokenContract, i) => {
    indexToTokenContract.set(i, tokenContract)
    calls.push({
      target: stakingContract.address,
      callData: stakingContract.interface.encodeFunctionData('stakedBalances', [
        account,
        tokenContract,
      ]),
    })
  })

  const results = await multicall.callStatic.tryAggregate(true, calls)

  const stakes: Record<string, BigNumber> = {}
  results.forEach((result, i) => {
    const tokenContract = indexToTokenContract.get(i)
    if (!tokenContract) throw new Error('Token contract not found')
    stakes[tokenContract] = stakingContract.interface.decodeFunctionResult(
      'stakedBalances',
      result.returnData
    )[0]
  })
  return stakes
}

export function usePreDepositStakes(tokenContracts: string[]) {
  const { account } = useSwellWeb3()
  const stakingContract = usePreDepositStakingContractView()
  const multicall = useMulticallContract()

  // filter out addresses that aren't on-chain (e.g. 0xee..ee for ETH)
  let addresses = uniq(tokenContracts)
  let requestedEth = false
  if (addresses.some((address) => isEthAddressAlias(address))) {
    requestedEth = true
    addresses = addresses.filter((address) => !isEthAddressAlias(address))
  }

  return useSWRImmutable(
    account ? ['pre-deposit-stakes', account, ...addresses] : undefined,
    async () => {
      if (!account) throw new Error('account is required')
      const stakes = await fetchPreDepositStakes(
        stakingContract!,
        multicall!,
        addresses,
        account
      )
      if (requestedEth) {
        stakes[ETH_ALIAS] = BigNumber.from(0) // no ETH staking, it is converted to weth
      }

      return { stakes }
    }
  )
}
