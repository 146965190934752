import { useZapApi } from './context'
import useSWR from 'swr'
import { ZapRouteParams } from './types'
import { useWeb3Call } from '@/hooks/useWeb3Call'
import { BigNumber, ethers } from 'ethers'
import { useMemo } from 'react'
import { Token } from '@/types/tokens'
import { useSwellWeb3 } from '@/swell-web3/core'
import { useDeploymentSetConfig } from '../deployments/hooks'

export function useZapRoutes(
  routeParams: ZapRouteParams | undefined,
  opts: {
    paused?: boolean
  } = {}
) {
  const paused = opts.paused || false
  const { read } = useZapApi()
  return useSWR(
    !paused && routeParams ? ['zapRoutes', routeParams] : null,
    () => read.getRoute(routeParams!)
  )
}

export function useZapContractAddress() {
  return useZapApi().read.contractAddress
}
export function useZapBuilding() {
  return useZapApi().building
}

export function useZap() {
  const { write } = useZapApi()

  return useWeb3Call({
    fn: write.zap,
    estimateGas: write.zapEstimateGas,
    validate: async ({
      route: {
        fromAmount,
        fromToken,
        raw: stepsRaw,
        toAmount,
        toToken,
        toAmountUSD, // ignored
        fromAmountUSD, // ignored
        steps,
      },
      slippage,
    }) => {
      let fromAmountBN: BigNumber
      let toAmountBN: BigNumber
      try {
        fromAmountBN = BigNumber.from(fromAmount)
      } catch (e) {
        return 'Invalid from amount'
      }
      try {
        toAmountBN = BigNumber.from(toAmount)
      } catch (e) {
        return 'Invalid to amount'
      }

      if (fromAmountBN.isZero()) {
        return 'From amount must be greater than 0'
      }
      if (toAmountBN.isZero()) {
        return 'To amount must be greater than 0'
      }

      if (!fromToken) {
        return 'Invalid from token'
      }

      if (!toToken) {
        return 'Invalid to token'
      }

      try {
        ethers.utils.getAddress(fromToken.address)
      } catch (e) {
        return 'Invalid from token address'
      }
      try {
        ethers.utils.getAddress(toToken.address)
      } catch (e) {
        return 'Invalid to token address'
      }
      if (!stepsRaw || steps.length === 0) {
        return 'Invalid steps'
      }

      if (slippage < 0 || slippage > 1) {
        return 'Invalid slippage'
      }

      return null
    },
  })
}
export type ZapCall = ReturnType<typeof useZap>

export function useRouteParams({
  fromAmount,
  fromToken,
  toToken,
  maxPriceImpact,
  slippage,
}: {
  fromToken: Token | undefined
  toToken: Token | undefined
  fromAmount: BigNumber | undefined
  slippage: number // ratio
  maxPriceImpact: number // ratio
}) {
  const { chainId } = useSwellWeb3()
  const { chainId: deploymentChainId } = useDeploymentSetConfig()

  return useMemo<ZapRouteParams | undefined>(() => {
    if (!fromToken || !toToken || !fromAmount) {
      return undefined
    }

    if (chainId !== deploymentChainId) return undefined

    return {
      fromChainId: chainId, // cross-chain routes not supported yet
      toChainId: chainId,
      fromAmount: fromAmount.toString(),
      fromToken: {
        address: fromToken.address,
        decimals: fromToken.decimals,
      },
      toToken: {
        address: toToken.address,
        decimals: toToken.decimals,
      },
      options: {
        slippage,
        maxPriceImpact,
      },
    } as ZapRouteParams
  }, [
    chainId,
    deploymentChainId,
    fromAmount,
    fromToken,
    maxPriceImpact,
    slippage,
    toToken,
  ])
}
