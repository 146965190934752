import { EcosystemPartnerInfo } from './types'
import altlayerLogo from '@/assets/images/predeposit/altlayer.png'
import ambientLogo from '@/assets/images/predeposit/ambient.png'
import bixinLogo from '@/assets/images/predeposit/bixin.png'
import brahmaLogo from '@/assets/images/predeposit/brahma.png'
import chainlinkLogo from '@/assets/images/predeposit/chainlink.png'
import eigendaLogo from '@/assets/images/predeposit/eigenda.png'
import eigenlayerLogo from '@/assets/images/predeposit/eigenlayer.png'
import eigenpieLogo from '@/assets/images/predeposit/eigenpie.png'
import etherfiLogo from '@/assets/images/predeposit/etherfi.png'
import frameworkLogo from '@/assets/images/predeposit/framework.png'
import hexensLogo from '@/assets/images/predeposit/hexens.png'
import ionLogo from '@/assets/images/predeposit/ion.png'
import iosgLogo from '@/assets/images/predeposit/iosg.png'
import lidoLogo from '@/assets/images/predeposit/lido.png'
import maven11Logo from '@/assets/images/predeposit/maven11.png'
import mixbytesLogo from '@/assets/images/predeposit/mixbytes.png'
import pendleLogo from '@/assets/images/predeposit/pendle.png'
import polygonLogo from '@/assets/images/predeposit/polygon.png'
import redstoneLogo from '@/assets/images/predeposit/redstone.png'
import renzoLogo from '@/assets/images/predeposit/renzo.png'
import spartanLogo from '@/assets/images/predeposit/spartan.png'
import stakestoneLogo from '@/assets/images/predeposit/stakestone.png'
import swellLogo from '@/assets/images/predeposit/swell.png'

const fraxLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6621d1e62f3a23452031405a_FRAX.svg'
const ethenaLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66304afbb2d8ae4b9a233b55_Ethena.svg'
const brevisLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66304b003521f91a5acc0e6c_Brevis.svg'
const sturdyFinanceLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6638739d6f4511ef50dbb091_Sturdy.svg'
const apolloCryptoLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/663874f35c61c859b398905d_Apollo.svg'
const kainSynthetixImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65b704135525915bb028480d_Kain%20Warwick.png'
const samFraxImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/663876ec21b6a99cbfa4f4e2_Sam.svg'
const michaelCurveImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/663877eb6f4511ef50df40ee_Michael.svg'
const tnPendleImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/663876ebe19f1be233b12e22_TN.svg'
const sandeepPolygonImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/663876ec845a1f1105a80ce2_Sandeep.svg'
const anthonySassanoImg =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65b70413ad24d6836fe9c5f2_Anthony%20Sassano.png'
const droseraLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6639889a5cf3c36621da47d9_Drosera.svg'
const gammaSwapLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/664aa857c43291692f42ea78_GammaSwap.svg'
const dittoLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/6655688570abf5569b5f1b9e_Ditto.svg'
const versatusLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66556885bac265137bbdf120_Versatus.svg'
const symbioticLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/66693e5d00dc4d1921a9395e_Symbiotic.svg'
const vedaLogo =
  'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/667b7ff894d6bd8352b5fd4f_Veda.svg'

export const ETH_ALIAS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'

export const L2_POWERED_BY_PARTNERS = [
  {
    name: 'Swell',
    logo: swellLogo,
  },
  {
    name: 'Eigenlayer',
    logo: eigenlayerLogo,
  },
  {
    name: 'Etherfi',
    logo: etherfiLogo,
  },
  {
    name: 'Renzo',
    logo: renzoLogo,
  },
  {
    name: 'Eigenpie',
    logo: eigenpieLogo,
  },
  {
    name: 'Stakestone',
    logo: stakestoneLogo,
  },
  {
    name: 'Symbiotic',
    logo: symbioticLogo,
  },
  {
    name: 'Lido',
    logo: lidoLogo,
  },
  {
    name: 'Pendle',
    logo: pendleLogo,
  },
  {
    name: 'FRAX',
    logo: fraxLogo,
  },
  {
    name: 'Ethena',
    logo: ethenaLogo,
  },
]

export const L2_INFRASTRUCTURE_PARTNERS: EcosystemPartnerInfo[] = [
  {
    name: 'Eigen DA',
    logo: eigendaLogo,
  },
  {
    name: 'Polygon',
    logo: polygonLogo,
  },
  {
    name: 'Altlayer',
    logo: altlayerLogo,
  },
  {
    name: 'Chainlink',
    logo: chainlinkLogo,
  },
  {
    name: 'Redstone',
    logo: redstoneLogo,
  },
  {
    name: 'Veda',
    logo: vedaLogo,
  },
]

export const L2_ECOSYSTEM_PROJECTS: EcosystemPartnerInfo[] = [
  {
    name: 'Ambient',
    category: 'dex',
    logo: ambientLogo,
  },
  {
    name: 'ION protocol',
    category: 'lending',
    logo: ionLogo,
  },
  {
    name: 'BRAHMA',
    category: 'infrastructure',
    logo: brahmaLogo,
  },
  {
    name: 'Sturdy Finance',
    category: 'lending',
    logo: sturdyFinanceLogo,
  },
  {
    name: 'GammaSwap',
    category: 'perps',
    logo: gammaSwapLogo,
  },
]

export const L2_ECOSYSTEM_AVS: EcosystemPartnerInfo[] = [
  {
    name: 'Brevis',
    category: 'infrastructure',
    logo: brevisLogo,
  },
  {
    name: 'Drosera',
    category: 'security',
    logo: droseraLogo,
  },
  {
    name: 'Ditto',
    category: 'infrastructure',
    logo: dittoLogo,
  },
  {
    name: 'Versatus',
    category: 'infrastructure',
    logo: versatusLogo,
  },
]

export const L2_AUDITED_BY: EcosystemPartnerInfo[] = [
  {
    name: 'Hexens',
    logo: hexensLogo,
  },
  {
    name: 'Mixbytes',
    logo: mixbytesLogo,
  },
]

export const L2_BACKED_BY: EcosystemPartnerInfo[] = [
  {
    name: 'Framework',
    logo: frameworkLogo,
  },
  {
    name: 'Maven 11',
    logo: maven11Logo,
  },
  {
    name: 'IOSG',
    logo: iosgLogo,
  },
  {
    name: 'Spartan',
    logo: spartanLogo,
  },
  {
    name: 'Bixin',
    logo: bixinLogo,
  },
  {
    name: 'Apollo Crypto',
    logo: apolloCryptoLogo,
  },
  {
    name: 'Kain',
    category: 'synthetix',
    logo: kainSynthetixImg,
  },
  {
    name: 'Sam',
    category: 'FRAX',
    logo: samFraxImg,
  },
  {
    name: 'Michael',
    category: 'Curve',
    logo: michaelCurveImg,
  },
  {
    name: 'TN',
    category: 'Pendle',
    logo: tnPendleImg,
  },
  {
    name: 'Sandeep',
    category: 'Polygon',
    logo: sandeepPolygonImg,
  },
  {
    name: 'Anthony Sassano',
    logo: anthonySassanoImg,
  },
]

export const etherFiLiquidUsdAddress =
  '0x08c6F91e2B681FaF5e17227F2a44C307b3C1364C'

export const NEW_PREDEPOSIT_TOKENS: Set<string> = new Set([
  '0xF0574d8B9Dc3a9DE768eaa7DBB7bB0C68521b148', // Swell 26 Sep 2024 | Pendle PT rswETH'
  '0x1c085195437738d73d75DC64bC5A3E098b7f93b1', // EtherFi 26 Sep 2024 | Pendle PT weETH'
  '0x248241244356D18f63d0c16082996839ecB0C7bF', // Renzo 26 Sep 2024 | Pendle PT ezETH'
  '0xd4e75971eAF78a8d93D96df530f1FFf5f9F53288', // Puffer 26 Sep 2024 | Pendle PT pufETH'
  '0x5fD13359Ba15A84B76f7F87568309040176167cd', // Amphor Restaked ETH | amphrETH'
  '0x422F5acCC812C396600010f224b320a743695f85', // Symbiotic Wrapped Binance Beacon ETH | DC_wBETH'
  '0xB26ff591F44b04E78de18f43B46f8b70C6676984', // Symbiotic Coinbase Wrapped Staked ETH | DC_cbETH'
  '0x03Bf48b8A1B37FBeAd1EcAbcF15B98B924ffA5AC', // Symbiotic Rocket Pool ETH | DC_rETH'
  '0x475D3Eb031d250070B63Fa145F0fCFC5D97c304a', // Symbiotic Mantle ETH | DC_mETH'
  '0x5198CB44D7B2E993ebDDa9cAd3b9a0eAa32769D2', // Symbiotic Staked Frax Ether | DC_sfrxETH'
  '0xBdea8e677F9f7C294A4556005c640Ee505bE6925', // Symbiotic Stader ETHx | DC_ETHx'
])
