import React from 'react'
import styled from 'styled-components/macro'
import { Routes, Route } from 'react-router-dom'
import { FlexRow } from '@swell-ui/FlexRow'
import {
  OperatorFooterLinks,
  StakingFooterLinks,
  RestakingOperatorFooterLinks,
  RestakingFooterLinks,
  PreDepositFooterLinks,
} from './ContractLinks'
import { GasPriceLink } from './GasPriceLink'

const FooterWrapper = styled.div`
  width: 100%;
  padding: 10px;
  padding-bottom: 7px;
  letter-spacing: 0.4px;
  opacity: 0.5;
  margin-top: 25px;
  margin-bottom: 60px;
  z-index: 10;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 0;
    margin-bottom: 0;
  }

  div:not(:last-child) {
    padding-right: 14px;
  }
`

const FooterFlex = styled(FlexRow)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }
`

function Footer() {
  return (
    <FooterWrapper>
      <FooterFlex>
        <Routes>
          <Route path="/operators/*" element={<OperatorFooterLinks />} />
          <Route
            path="/restaking-operators/*"
            element={<RestakingOperatorFooterLinks />}
          />
          <Route path="/restake" element={<RestakingFooterLinks />} />
          <Route path="/swell-l2" element={<PreDepositFooterLinks />} />
          <Route path="/*" element={<StakingFooterLinks />} />
        </Routes>
        <GasPriceLink />
      </FooterFlex>
    </FooterWrapper>
  )
}

export { Footer }
