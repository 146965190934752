import useSWRImmutable from 'swr/immutable'
import { useGeoFenceApi } from './context'
import { IGeoFenceApi } from './types'
import { useDeploymentSetConfig } from '../deployments/hooks'
import { useV3BackendClient } from '@/services/V3BackendService/hooks'
import { useState } from 'react'

export function useIsGeoRestricted() {
  const [hitDeadline, setHitDeadline] = useState(false)
  const geoFenceApi = useGeoFenceApi()

  const query = useSWRImmutable(
    'isGeoRestricted',
    geoFenceApi.isGeoRestricted,
    {
      loadingTimeout: 800,
      shouldRetryOnError: true,
      onLoadingSlow: () => setHitDeadline(true),
    }
  )

  return { ...query, hitDeadline }
}

export function useGeoFenceApiImplV3Backend(): IGeoFenceApi {
  const { v3BackendPublicUrl } = useDeploymentSetConfig()
  const { v3BackendClient } = useV3BackendClient(v3BackendPublicUrl)

  return {
    isGeoRestricted: async () => {
      // empty string IP is resolved as the peer IP address on the backend
      const resp = await v3BackendClient.wallet.checkIP({ ip: '' })
      return {
        isRestricted: resp.isRestricted,
        countryCode: resp.countryCode,
        region: resp.region,
        ip: resp.ip,
        timestamp: resp.timestamp,
      }
    },
  }
}

export function useGeoFenceApiImplMock({
  delay,
  result,
}: {
  delay: number
  result: boolean
}): IGeoFenceApi {
  return {
    isGeoRestricted: async () => {
      await new Promise((resolve) => setTimeout(resolve, delay))
      return {
        isRestricted: result,
        countryCode: 'US',
        region: 'CA',
        ip: '0.0.0.0',
        timestamp: Date.now().toString(),
      }
    },
  }
}
