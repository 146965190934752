import React, { useEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'
import { v4 as uuid } from 'uuid'

export interface IDecoBoxProps {
  widthOverride?: string
  fixedHeight?: string
  nobreak?: boolean
  opaque?: boolean
  children?: React.ReactNode
}

function DecoBox(props: IDecoBoxProps) {
  const boxRef = useRef<HTMLDivElement>(null)

  const ID = useMemo(() => {
    return {
      shape: `${uuid()}-s`,
      borderMask: `${uuid()}-bm`,
      borderGradient: `${uuid()}-bg`,
    }
  }, [])

  const [width, setWidth] = React.useState(0)
  const [height, setHeight] = React.useState(0)

  useEffect(() => {
    let done = false
    const animate = () => {
      if (boxRef.current && !done) {
        setWidth(boxRef.current.clientWidth)
        setHeight(boxRef.current.clientHeight)
        requestAnimationFrame(animate)
      }
    }
    animate()
    return () => {
      done = true
    }
  }, [])

  return (
    <DecoBoxLayout {...props} ref={boxRef}>
      {/* motivation for svg: cannot use border-radius with border-image (gradient) */}
      <svg
        width={width ? width : '100%'}
        height={height ? height : '100%'}
        overflow="visible"
        pointerEvents="none"
      >
        <defs>
          <rect
            id={ID.shape}
            x="0"
            y="0"
            rx="12"
            ry="12"
            width="100%"
            height="100%"
          />
          <mask id={ID.borderMask}>
            <use
              href={`#${ID.shape}`}
              fillOpacity="0"
              stroke="white"
              strokeWidth="1"
            />
          </mask>
          <linearGradient
            id={ID.borderGradient}
            x1="50%"
            y1="0"
            x2="50%"
            y2="1"
          >
            <stop offset="0%" stopColor="#fff" stopOpacity="0.4" />
            <stop offset="100%" stopColor="#fff" stopOpacity="0" />
          </linearGradient>
        </defs>
        <g mask={`url(#${ID.borderMask})`}>
          <use
            href={`#${ID.shape}`}
            fill={`url(#${ID.borderGradient})`}
            fillOpacity={1}
            transform="scale(1.5)"
            transform-origin="center"
          />
        </g>
      </svg>
      {props.children}
    </DecoBoxLayout>
  )
}

export { DecoBox }

const DecoBoxLayout = styled.div<IDecoBoxProps>`
  width: ${(props) => props.widthOverride};
  height: ${(props) => props.fixedHeight};
  display: flex;
  flex-flow: column nowrap;

  padding: 24px;
  border-radius: 12px;
  border-width: 0.8px solid transparent;

  ${({ nobreak }) =>
    nobreak &&
    css`
      white-space: nowrap;
    `}

  position:relative;
  > svg {
    position: absolute;
    inset: 0;
  }

  ${({ opaque }) =>
    opaque
      ? css`
          background: rgba(1, 3, 20, 0.9);
        `
      : css`
          background: rgba(1, 3, 20, 0.4);
        `}
  backdrop-filter: blur(12px);
`
