export const TOKEN_LIST_ETH = {
  chainId: 1,
  address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  name: 'Ethereum',
  symbol: 'ETH',
  decimals: 18,
  logoURI: '',
}

export const TOKEN_LIST_SWETH = {
  chainId: 1,
  address: '0xf951e335afb289353dc249e82926178eac7ded78',
  name: 'Swell Ethereum',
  symbol: 'swETH',
  decimals: 18,
  logoURI:
    'https://assets.coingecko.com/coins/images/30326/thumb/_lB7zEtS_400x400.jpg?1684128260',
}

export const TOKEN_LIST_RSWETH = {
  chainId: 1,
  address: '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0',
  name: 'Restaked Swell Ethereum',
  symbol: 'rswETH',
  decimals: 18,
  logoURI:
    'https://assets.coingecko.com/coins/images/34489/thumb/Restaked_Swell_ETH.png?1705048450',
}
