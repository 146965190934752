import { BigNumber } from 'ethers'
import { formatUnits } from 'ethers/lib/utils'
import { formatWithConfig } from './number'

type Opts = {
  precision: number
  localize: boolean
}

function displayCrypto(
  amountBN: BigNumber,
  decimals = 18,
  options: Opts = { precision: 4, localize: false }
) {
  const floatAmount = parseFloat(formatUnits(amountBN, decimals))

  const formattedFloat = formatWithConfig(floatAmount, {
    precision: options.precision,
    localize: options.localize,
  })

  return formattedFloat
}

export { displayCrypto }
