import React from 'react'
import { useRestakingDepositCollectionValidationInput } from '@/state/restakingDepositValidation/hooks'
import useRestakingDepositCollectionValidation from '@/hooks/useRestakingDepositCollectionValidation'
import { DepositInfo } from '@/types/deposits'
import { UploadDepositCollectionCommon } from './UploadDepositCollectionCommon'

interface RestakingUploadDepositCollectionWidgetProps {
  onSubmit: (chunks: DepositInfo[][]) => void
  preventInteraction: boolean
  setTextAreaRef: any
}

export function RestakingUploadDepositCollectionWidget(
  props: RestakingUploadDepositCollectionWidgetProps
) {
  const depositCollectionValidationInput =
    useRestakingDepositCollectionValidationInput()
  const depositCollectionValidation = useRestakingDepositCollectionValidation()

  return (
    <UploadDepositCollectionCommon
      depositCollectionValidationInput={depositCollectionValidationInput}
      depositCollectionValidation={depositCollectionValidation}
      {...props}
    />
  )
}
