import React from 'react'
import { Grid } from '@swell-ui/Grid'
import { OfferCard } from './OfferCard'

const offers = [
  {
    title: 'Super swETH',
    imgUrl:
      'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65c482725952fe72a10f7b9b_supersweth.png',
    tokens: ['stETH', 'ETH'],
    applicable: 'stETH and ETH holders',
    offerShort: 'Deposit stETH to earn supercharged rewards',
    timeLimit: 'April 22 2024',
    offerLong:
      'The Super swETH Enzyme vault offers boosted staking yield from Swell DAO treasury revenue, and up to 3x Pearls for early depositors. stETH deposited is swapped into swETH, helping to diversify Ethereum.',
    ctaLabel: 'Supercharge your staking yield',
    ctaLink: 'https://swellnetwork.io/vault',
  },
  {
    title: 'Minting bonus',
    imgUrl:
      'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65c482723c6b840079fecede_minting.png',
    tokens: ['swETH'],
    applicable: 'Stakers',
    offerShort: '30 Pearls per swETH',
    timeLimit: 'Unlimited',
    offerLong:
      'Get 30 Pearls for every swETH or rswETH that you mint through the app.',
    ctaLabel: 'Launch app',
    ctaLink: 'https://app.swellnetwork.io',
  },
  {
    title: 'Swell Voyage Pearls',
    imgUrl:
      'https://uploads-ssl.webflow.com/63d9b0d075e8161e9281ebdd/65c4827214af8ba83d30d2c5_swellvoyagepearls.png',
    tokens: ['swETH', 'rswETH'],
    applicable: 'swETH and rswETH holders',
    offerShort: 'Earn Pearls for holding and providing liquidity',
    timeLimit: 'Unlimited',
    offerLong:
      'Earn ongoing Pearls at the base rate for holding swETH and rswETH, and earn Pearls at a higher rate for using them in approved DeFi protocols.',
    ctaLabel: 'Join the Voyage',
    ctaLink: 'https://app.swellnetwork.io/voyage',
  },
]

export function OfferGrid() {
  return (
    <Grid container rowSpacing={7} columnSpacing={3}>
      {offers.map((offer: any) => {
        return (
          <Grid key={offer.title} item xs={12} md={4}>
            <OfferCard offer={offer} />
          </Grid>
        )
      })}
    </Grid>
  )
}
