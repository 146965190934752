import React from 'react'
import styled from 'styled-components'
import { useSwellWeb3 } from '@swell-web3/core'
import { Chip } from '@swell-ui/Chip'
import { FormattedEthBalance } from '@/components/FormattedEthBalance'
import { displayCrypto } from '@/util/displayCrypto'

const AvailableChipContainer = styled.div<any>`
  height: 32px;
  position: relative;
  top: 12px;
  ${({ disabled }) => disabled && 'opacity: 0.3;'}
`

const StyledChip = styled(Chip)`
  margin-bottom: 10px;
  border-color: ${({ theme }) => theme.chip.color};
`

function AvailableChip({
  token,
  tokenBalances,
}: {
  token?: any
  tokenBalances?: any
}) {
  const { account } = useSwellWeb3()

  let balanceDisplay = '0'
  if (token && tokenBalances) {
    const tokenBalance = tokenBalances.find(
      (t: any) => t.address === token.address
    )
    if (tokenBalance) {
      balanceDisplay = displayCrypto(tokenBalance.balance, token.decimals, {
        precision: 4,
        localize: true,
      })
    }
  }

  const isEth = !token || token.symbol === 'ETH'

  return (
    <AvailableChipContainer disabled={!account}>
      <StyledChip
        variant="outlined"
        label={
          <>
            {!account && <>- Available</>}
            {account && isEth && <FormattedEthBalance helperText="Available" />}
            {account && !isEth && (
              <span>
                {balanceDisplay} {token.symbol} Available
              </span>
            )}
          </>
        }
      />
    </AvailableChipContainer>
  )
}

export { AvailableChip }
