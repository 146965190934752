import { L2 } from '@/swell-ui/icons/L2Icon'
import React from 'react'
import styled from 'styled-components'

interface ISwellL2LogoProps {
  children?: React.ReactNode
}

const SwellL2Logo: React.FC<ISwellL2LogoProps> = () => {
  return (
    <L>
      <S>Swell</S>
      <L2 />
    </L>
  )
}

export default SwellL2Logo

const S = styled.span`
  color: var(--white-50, #fff);
  text-align: center;
  /* Headlines/H3 */
  font-family: Inter;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */
  letter-spacing: -1.44px;
`

const L = styled.div`
  display: flex;
  flex-flow: row nowrap;

  svg {
    width: 42px;
    height: 42px;
    margin-left: 10px;
    margin-top: 6px;
  }
`
