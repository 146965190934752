import { useDepositCollectionValidatorInstance } from '@/services/DepositCollectionValidator/hooks'
import {
  useDepositCollectionValidationState,
  useValidateDepositCollection,
} from '@/state/depositValidation/hooks'
import { IDepositCollectionValidationResult } from '@/state/depositValidation/models/DepositCollectionValidationResult'
import { ProgressPayload } from '../../services/TaskRunner/ITaskRunner'
import { useDeploymentSetConfig } from '@/state/deployments/hooks'
import useChainDepositConfig from '@/state/deployments/hooks/useChainDepositConfig'

export interface IUseDepositCollectionValidationArgs {
  input: string
}

export interface IUseDepositCollectionValidationOutput {
  validationResult: IDepositCollectionValidationResult | undefined
  validating: boolean
  validationProgress: ProgressPayload | undefined

  validate: () => void
}

const useStakingDepositCollectionValidation =
  (): IUseDepositCollectionValidationOutput => {
    const { v3BackendLstUrl } = useDeploymentSetConfig()
    const { validating, validationProgress, validationResult } =
      useDepositCollectionValidationState()

    const chainDepositConfig = useChainDepositConfig()

    // This hook is used for verifying swETH deposits, hence the LST backend is hardcoded.
    // It is important to use the correct backend, because rswETH/swETH have different withdrawal credentials.
    const depositCollectionValidator = useDepositCollectionValidatorInstance(
      v3BackendLstUrl,
      chainDepositConfig
    )
    const validate = useValidateDepositCollection(depositCollectionValidator)

    return {
      validate,
      validating,
      validationProgress,
      validationResult,
    }
  }

export default useStakingDepositCollectionValidation
