import { useState } from 'react'
import { useSwellWeb3 } from '@swell-web3/core'
import { BigNumber } from 'ethers'
import { useSwExitContract } from '@/hooks/useContract'
import { calculateGasMargin } from '@/util/calculateGasMargin'
import {
  getFinalizeWithdrawalUpperGasEstimate,
  getStakingUpperGasEstimate,
} from '@/constants/gasEstimates'
import { Claim } from '@/types/claims'

export interface FinalizeWithdrawalTransactionResponse {
  tx?: any
  error?: {
    message: string
  }
}

export interface UseFinalizeWithdrawal {
  isLoading: boolean
  clear: () => void
  sendTransaction: (
    claim: Claim
  ) => Promise<FinalizeWithdrawalTransactionResponse>
  tokenId: string
  ethAmount: number
  setStatus: (status: string) => void
  status: string
  STATUS: {
    [key: string]: string
  }
  tx: any
}

export function useFinalizeWithdrawal(): UseFinalizeWithdrawal {
  const { account, provider } = useSwellWeb3()
  const swExitContract = useSwExitContract()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tokenId, setTokenId] = useState<string>('')
  const [ethAmount, setEthAmount] = useState<number>(-1)

  const STATUS = {
    IDLE: 'idle',
    PROMPTING: 'prompting',
    PENDING: 'pending',
    FULFILLED: 'fulfilled',
    ERROR: 'error',
  }
  const [status, setStatus] = useState<string>(STATUS.IDLE)
  const [tx, setTx] = useState<any>(undefined)

  const clear = () => {
    setStatus(STATUS.IDLE)
    setTx(undefined)
  }

  const sendTransaction = async (claim: Claim) => {
    if (!swExitContract) {
      return {
        error: { message: 'SWExit contract undefined.' },
      }
    }

    if (!claim) {
      return {
        error: { message: 'No claim specified.' },
      }
    }

    setTokenId(claim.tokenId)
    setEthAmount(claim.ethAmount)

    setIsLoading(true)

    let tx
    try {
      const gasLimit = getFinalizeWithdrawalUpperGasEstimate()

      setStatus(STATUS.PROMPTING)

      const tx = await swExitContract.finalizeWithdrawal(claim.tokenId, {
        gasLimit: calculateGasMargin(gasLimit),
      })
      setTx(tx)
      setStatus(STATUS.PENDING)
      await tx.wait()

      setIsLoading(false)
      setStatus(STATUS.FULFILLED)
      return {
        tx: tx,
      }
    } catch (error: any) {
      setIsLoading(false)
      return {
        error: { message: error.reason || 'Unknown error' },
      }
    }
  }

  return {
    isLoading,
    clear,
    sendTransaction,
    tokenId,
    ethAmount,
    setStatus,
    status,
    STATUS,
    tx,
  }
}
