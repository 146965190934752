import React from 'react'

function ArrowRightIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="4 2 14 14"
      fill="none"
    >
      <g filter="url(#filter0_dd_6491_964)">
        <path
          d="M4.8125 8.335H4.1475V9.665H4.8125V8.335ZM17.1875 9.665C17.5548 9.665 17.8525 9.36727 17.8525 9C17.8525 8.63273 17.5548 8.335 17.1875 8.335V9.665ZM4.8125 9.665H17.1875V8.335H4.8125V9.665Z"
          fill="white"
        />
        <path
          d="M12.125 3.9375L17.1875 9L12.125 14.0625"
          stroke="white"
          strokeWidth="1.33"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export { ArrowRightIcon }
