import styled from 'styled-components'
import { Button } from '@swell-ui/Button'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { Dialog, DialogContent } from '@swell-ui/Dialog'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'

interface UnstakeConfirmModalProps {
  open: boolean
  onClose: (confimed: boolean) => any
}

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 425px;
    padding: 42px 32px;
    text-align: center;

    ${({ theme }) => theme.breakpoints.down('md')} {
      padding: 24px 16px;
    }
  }
`

const TextFlex = styled(FlexRow)`
  padding: 0 26px;
`

const FlexA = styled.a`
  display: inline-block;
  flex-basis: 50%;
`

const FlexButton = styled(Button)`
  flex-basis: 50%;
  padding-left: 0;
  padding-right: 0;
`

const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 12px;
  right: 12px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  path {
    stroke-width: 0.666667px;
    stroke: ${({ theme }) => theme.mainColor};
  }
`

const PARASWAP_LINK =
  'https://app.paraswap.io/#/0xf951e335afb289353dc249e82926178eac7ded78-0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE/3089.25/SELL?network=ethereum'

const SLink = styled.a`
  color: white;
  text-decoration: underline !important;

  :hover {
    color: #d6dfe9;
  }
`

export function UnstakeConfirmModal({
  open,
  onClose,
}: UnstakeConfirmModalProps) {
  const cancel = () => {
    onClose(false)
  }

  const confirm = () => {
    onClose(true)
  }

  return (
    <StyledDialog open={open} fullWidth onClose={cancel}>
      <StyledCloseIcon onClick={cancel} />
      <FlexRow direction="column" gap="42">
        <TextFlex direction="column" gap="24">
          <Typography variant="body" size="large" letterSpacing="small">
            Processing time
          </Typography>
          <Typography variant="body" size="xsmall" letterSpacing="small">
            Processing an unstake request will take on average ~12 days but can
            be delayed{' '}
            <SLink
              href="https://www.validatorqueue.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              longer
            </SLink>
            .
            <br />
            <br />
            Do you want to continue?
          </Typography>
        </TextFlex>
        <FlexRow gap="12">
          <FlexButton variant="secondary" size="small" onClick={cancel}>
            Cancel
          </FlexButton>
          <FlexButton variant="primary" size="small" onClick={confirm}>
            Request unstake
          </FlexButton>
        </FlexRow>
      </FlexRow>
    </StyledDialog>
  )
}
