import React from 'react'
import useTransactionFee from '@/hooks/useTransactionFee'
import { useRestakingApr, useRestakingRate } from '@/state/restakingStats/hooks'
import { AsyncDiv } from '@/swell-ui/AsyncDiv'
import { KeyValueList } from '@/swell-ui/KeyValueList'
import {
  useDisplayRswEth,
  useDisplayRswEthInNativeCurrency,
} from '@/hooks/useRswEthDisplay'
import { useParseRswEth } from '@/hooks/useRswEthFormatting'
import { useDisplayNativeCurrencyPriceFiat } from '@/hooks/useNativeCurrencyDisplay'
import { getStakingUpperGasEstimate } from '@/constants/gasEstimates'
import { bigNumberRateToFloat } from '@/util/big'

export function RswethExchangeInfo() {
  const restakingApr = useRestakingApr()
  const restakingRate = useRestakingRate()
  const restakingTransactionFee = useTransactionFee({
    gasAmount: getStakingUpperGasEstimate().toNumber(),
  })

  const parseRswEth = useParseRswEth()
  const displayRswEth = useDisplayRswEth()
  const displayRswEthInNativeCurrency = useDisplayRswEthInNativeCurrency()
  const displayNativeCurrencyPriceFiat = useDisplayNativeCurrencyPriceFiat()

  const rswethAprConfig = {
    label: 'rswETH APR',
    value: (
      <AsyncDiv loading={!restakingApr.data}>
        {() => `${restakingApr.data!.value}%`}
      </AsyncDiv>
    ),
    infoText: (
      <span>
        <a
          href="https://dune.com/swell-network/swell-network"
          target="_blank"
          rel="noreferrer"
        >
          Total pool 7 day moving average
        </a>
      </span>
    ),
    hasInfo: true,
  }

  const exchangeRateConfig = {
    label: 'Exchange rate',
    value: (
      <AsyncDiv loading={!restakingRate.data}>
        {() => {
          const { rswETHToETHRate } = restakingRate.data!

          const oneRswEth = parseRswEth('1')

          const oneRswEthDisplay = displayRswEth(oneRswEth, {
            precision: 4,
            localize: true,
          })
          const equivalentEthDisplay = displayRswEthInNativeCurrency(
            oneRswEth,
            bigNumberRateToFloat(rswETHToETHRate),
            { precision: 4, localize: true }
          )

          return `${oneRswEthDisplay} = ${equivalentEthDisplay}`
        }}
      </AsyncDiv>
    ),
    hasInfo: true,
    infoText: 'Represents the accruing value of rswETH vs ETH',
  }

  const exchangeInfoConfig = {
    label: 'Transaction fee',
    value: (
      <AsyncDiv loading={!restakingTransactionFee.data}>
        {() => {
          const { ethUsdRate, feeWei } = restakingTransactionFee.data!
          return `${displayNativeCurrencyPriceFiat(feeWei, ethUsdRate)} USD`
        }}
      </AsyncDiv>
    ),
  }

  const exchangeInfoListConfig = {
    list: [rswethAprConfig, exchangeRateConfig, exchangeInfoConfig],
  }

  return <KeyValueList config={exchangeInfoListConfig} />
}
