import React, { ReactNode, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom'
import { useLoadOkxMobileWallet } from '@swell-web3/okx'
import { Container } from '@swell-ui/Container'
import { FlexRow } from '@swell-ui/FlexRow'
import { GlobalNotificationContainer } from '@swell-ui/GlobalNotification'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { TopNavBar } from '@/components/TopNavBar'
import { StakingPage } from '@/pages/StakingPage'
import { Restaking } from '@/pages/Restaking'
import { Earn } from '@/pages/Earn'
import { BonusOffers } from '@/pages/BonusOffers'
import { AllOperators } from '@/pages/AllOperators'
import { OperatorDashboard } from '@/pages/OperatorDashboard'
import { Portfolio } from '@/pages/Portfolio'
import { SwelL2 } from '@/pages/SwelL2'
import { ValidatorKeys } from '@/pages/ValidatorKeys'
import { Voyage } from '@/pages/Voyage'
import { AllOperators as AllRestakingOperators } from '@/pages/restaking-operators/AllOperators'
import { Dashboard as RestakingOperatorDashboard } from '@/pages/restaking-operators/Dashboard'
import { ValidatorKeys as RestakingValidatorKeys } from '@/pages/restaking-operators/ValidatorKeys'
import { BottomNavBar } from '@/components/BottomNavBar'
import { Footer } from '@/components/Footer'
import { Web3NavWidget } from '@/components/Web3NavWidget'
import { OceanBackground } from '@/components/OceanBackground'
import { VoyageBgImg } from '@/components/VoyageBgImg'
import { L2Background } from '@/components/L2Background'
import { Helmet } from 'react-helmet'
import { DepositSubmissionDetailsProvider } from './state/depositSubmission/hooks'
import { RestakingDepositSubmissionDetailsProvider } from './state/restakingDepositSubmission/hooks'
import { AppBanner } from './components/AppBanner'
import { PreDepositZapProvider } from './state/predeposit/zap/context'
import { useIsGeoRestricted } from './state/geofence/hooks'
import aquanautSadUrl from './assets/images/aquanaut-sad-158x158.png'
import { DecoBox } from './components/DecoBox'
import { GeoFenceResult } from './state/geofence/types'

const StyledGlobalNotificationContainer = styled(GlobalNotificationContainer)`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 100;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const AppContainer = styled(Container)`
  padding-top: 120px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  z-index: 10;

  opacity: 0;
  animation: ${fadeIn} 0.65s ease-out forwards;
  animation-delay: 0;
`

const GeoRestrictedLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 0;

  img {
    width: 158px;
    height: 158px;
  }

  p {
    margin: 0;
  }

  * {
    text-align: center;
  }

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translate(0, -79px);

  ${({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      transform: translate(-50%, -50%);

      h1 {
        font-size: 24px;
      }

      img {
        width: 79px;
        height: 79px;
      }
    }
  `}
`

const AppInner = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  flex: 1 1 auto;
  max-width: 1536px;
  padding-left: 36px;
  padding-right: 36px;
  margin: 0 auto;

  ${({ theme }) => `
    ${theme.breakpoints.down('md')} {
      padding-left: 16px;
      padding-right: 16px;
    }
  `}
`

const InnerWallet = styled(FlexRow)`
  margin-bottom: 24px;
`

const BANNER_ROUTES: string[] = [
  // '/',
  // '/earn',
  // '/portfolio',
  // '/voyage',
  // '/stake',
  // '/restake',
  // '/bonus-offers',
  // '/swell-l2',
]

function App() {
  const isGeoRestrictedQuery = useIsGeoRestricted()

  let hideNav: boolean
  let content: ReactNode
  let background = (
    <Routes>
      <Route path="voyage" element={<VoyageBgImg />} />
      <Route path="swell-l2" element={<L2Background />} />
      <Route path="*" element={<OceanBackground />} />
    </Routes>
  )
  if (isGeoRestrictedQuery.data?.isRestricted === true) {
    content = <ContentGeoRestricted result={isGeoRestrictedQuery.data!} />
    background = <OceanBackground />
    hideNav = true
  } else if (isGeoRestrictedQuery.error || isGeoRestrictedQuery.hitDeadline) {
    content = <ContentLoaded />
    hideNav = false
  } else if (isGeoRestrictedQuery.data === undefined) {
    content = <ContentLoading />
    hideNav = true
  } else {
    content = <ContentLoaded />
    hideNav = false
  }

  return (
    <>
      <Helmet>
        <title>Swell: Liquid restaking for DeFi</title>
      </Helmet>
      <div style={{ zIndex: 11, position: 'relative', height: 0 }}>
        <TopNavBar onlyLogo={hideNav} />
      </div>
      {content}
      {background}
    </>
  )
}

export default App

function ContentGeoRestricted({ result }: { result: GeoFenceResult }) {
  const navigate = useNavigate()

  // reason for navigating: restake page has hardcoded global CSS which does not look good with this content
  useEffect(() => {
    navigate('/restricted')
  }, [navigate])

  return (
    <AppContainer>
      <GeoRestrictedLayout>
        <img src={aquanautSadUrl} alt="Aquanaut sad" width="158" height="158" />
        <DecoBox>
          <h1>Access restricted</h1>
          <p>Unfortunately Swell is not available in your country right now.</p>
          <p>
            For more information please read our{' '}
            <a
              href="https://www.swellnetwork.io/legal/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of service
            </a>
            .
          </p>
          <pre>
            <div>Time: {result.timestamp}</div>
            <div>Country: {result.countryCode}</div>
            <div>Region: {result.region}</div>
            <div>IP: {result.ip}</div>
          </pre>
        </DecoBox>
      </GeoRestrictedLayout>
    </AppContainer>
  )
}

function ContentLoading() {
  return <></>
}

function ContentLoaded() {
  const loadOkxMobileWallet = useLoadOkxMobileWallet()

  useEffect(() => {
    loadOkxMobileWallet()
  }, [loadOkxMobileWallet])

  const is1040Up = useMediaQuery('(min-width:1040px)')

  return (
    <AppContainer maxWidth="xl">
      <StyledGlobalNotificationContainer />
      <AppInner>
        {!is1040Up && (
          <InnerWallet justify="right">
            <Web3NavWidget />
          </InnerWallet>
        )}
        <Routes>
          {BANNER_ROUTES.map((route: string) => {
            return <Route key={route} path={route} element={<AppBanner />} />
          })}
        </Routes>
        <Routes>
          <Route index element={<Navigate to="/restake" />} />
          <Route path="stake" element={<StakingPage />} />
          <Route path="restake" element={<Restaking />} />
          <Route path="voyage" element={<Voyage />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="earn" element={<Earn />} />
          <Route path="bonus-offers" element={<BonusOffers />} />
          <Route
            path="swell-l2"
            element={
              <PreDepositZapProvider>
                <SwelL2 />
              </PreDepositZapProvider>
            }
          />
          <Route path="operators">
            <Route index element={<Navigate to="/operators/dashboard" />} />
            <Route path="dashboard" element={<OperatorDashboard />} />
            <Route path="all-operators" element={<AllOperators />} />
            <Route
              path="validator-keys"
              element={
                <DepositSubmissionDetailsProvider>
                  <ValidatorKeys />
                </DepositSubmissionDetailsProvider>
              }
            />
            <Route path="*" element={<Navigate to="/operators/dashboard" />} />
          </Route>
          <Route path="restaking-operators">
            <Route
              index
              element={<Navigate to="/restaking-operators/dashboard" />}
            />
            <Route path="dashboard" element={<RestakingOperatorDashboard />} />
            <Route path="all-operators" element={<AllRestakingOperators />} />
            <Route
              path="validator-keys"
              element={
                <RestakingDepositSubmissionDetailsProvider>
                  <RestakingValidatorKeys />
                </RestakingDepositSubmissionDetailsProvider>
              }
            />
          </Route>
          <Route path="vault" element={<Navigate to="/" />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </AppInner>
      <Routes>
        <Route path="voyage" element={<></>} />
        <Route path="*" element={<Footer />} />
      </Routes>
      <BottomNavBar />
    </AppContainer>
  )
}
