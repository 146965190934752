import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { SwellWeb3Provider } from '@swell-web3/core'
import { SwellUiThemeProvider } from '@swell-ui/theme/SwellUiThemeProvider'
import store from '@/state'
import ApplicationUpdater from '@/state/application/updater'
import FiatUpdater from './state/fiat/updater'
import GasUpdater from '@/state/gas/updater'
import { BlockNumberProvider } from './hooks/useBlockNumber'
import { SWRConfig } from 'swr'
import './i18n'
import '@reach/dialog/styles.css'
import UserUpdater from './state/user/updater'
import { ENABLE_DEV_GUI } from './configuration/featureFlags'
import { HOST_ENV, HostEnv } from './configuration/hostEnv'
import { env } from './env'
import WrongChainDetection from './components/WrongChainDetection'
import { GeoFenceContext } from './state/geofence/context'
import { useGeoFenceApiImplV3Backend } from './state/geofence/hooks'
import { ZapContext } from './state/zap/context'
import { useZapApiLifi } from './state/zap/lifi'
import { useZapApiParaswap } from './state/zap/paraswap'

const DevGui = lazy(() => import('./components/DevGui'))

const GTM_ID = env.REACT_APP_GTM_ID

if (GTM_ID) {
  TagManager.initialize({ gtmId: GTM_ID })
} else if (HOST_ENV === HostEnv.PRODUCTION) {
  console.warn(
    'No GTM_ID in production. Google Tag Manager was not initialized.'
  )
}

function Updaters() {
  return (
    <>
      <ApplicationUpdater />
      <GasUpdater />
      <FiatUpdater />
      <UserUpdater />
    </>
  )
}

function APIProviders({ children }: { children?: any }) {
  const geoFenceAPI = useGeoFenceApiImplV3Backend()
  const zapApi = useZapApiParaswap()
  return (
    <GeoFenceContext.Provider value={geoFenceAPI}>
      <ZapContext.Provider value={zapApi}>{children}</ZapContext.Provider>
    </GeoFenceContext.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <SWRConfig>
          <SwellWeb3Provider>
            <BlockNumberProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SwellUiThemeProvider>
                  <APIProviders>
                    <WrongChainDetection />
                    <Updaters />
                    <App />
                    {ENABLE_DEV_GUI && (
                      <Suspense fallback={null}>
                        <DevGui />
                      </Suspense>
                    )}
                  </APIProviders>
                </SwellUiThemeProvider>
              </LocalizationProvider>
            </BlockNumberProvider>
          </SwellWeb3Provider>
        </SWRConfig>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
