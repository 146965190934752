import React from 'react'
import styled from 'styled-components'
import { Button } from '@swell-ui/Button'
import { FlexRow } from '@swell-ui/FlexRow'
import { Typography } from '@swell-ui/Typography'
import { ArrowRightIcon } from '@swell-ui/icons/ArrowRightIcon'
import { EthIcon } from '@swell-ui/icons/EthIcon'
import { WstethIcon } from '@swell-ui/icons/WstethIcon'
import { SwethIcon } from '@swell-ui/icons/SwethIcon'
import { RswethIcon } from '@swell-ui/icons/RswethIcon'

const OfferCardContainer = styled(FlexRow)`
  position: relative;
  padding: 24px;
  border-radius: 12px;
  border: 2px solid #3068ef;
  background: rgba(11, 20, 27, 0.4);
  backdrop-filter: blur(5px);
  height: 100%;
`

const OfferLongContainer = styled.div`
  display: flex;
  flex-grow: 1;
`

const OfferImage = styled.img`
  width: 100%;
`

const ListKey = styled.span`
  color: ${({ theme }) => theme.colors.lightBlue['50']};
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 1.68px;
  text-transform: uppercase;
`

const ListValue = styled.span`
  font-size: 14px;
  line-height: 160%;
  letter-spacing: -0.42px;
`

const StyledEthIcon = styled(EthIcon)`
  width: 22px;
  height: 22px;
`

const StyledSwethIcon = styled(SwethIcon)`
  width: 22px;
  height: 22px;
`

const StyledStethIcon = styled(WstethIcon)`
  width: 22px;
  height: 22px;
`

const StyledRswethIcon = styled(RswethIcon)`
  width: 22px;
  height: 22px;
`

const CtaLink = styled.a`
  display: block;
  width: 100%;
`

const ICON_MAP: any = {
  ETH: <StyledEthIcon />,
  stETH: <StyledStethIcon />,
  swETH: <StyledSwethIcon />,
  rswETH: <StyledRswethIcon />,
}

export function OfferCard({ offer }: any) {
  return (
    <OfferCardContainer direction="column" align="flex-start" gap="24">
      <Typography variant="headline" size="h5">
        {offer.title}
      </Typography>
      <OfferImage src={offer.imgUrl} />
      <FlexRow direction="column" align="flex-start" gap="6">
        <FlexRow gap="6">
          <ListKey>Token:</ListKey>
          <FlexRow gap="12" width="auto">
            {offer.tokens.map((token: string) => {
              return (
                <FlexRow gap="6">
                  {ICON_MAP[token]}
                  <ListValue>{token}</ListValue>
                </FlexRow>
              )
            })}
          </FlexRow>
        </FlexRow>
        <div>
          <ListKey>Eligible:</ListKey>&nbsp;
          <ListValue>{offer.applicable}</ListValue>
        </div>
        <div>
          <ListKey>Offer:</ListKey>&nbsp;
          <ListValue>{offer.offerShort}</ListValue>
        </div>
        <div>
          <ListKey>Time Limit:</ListKey>&nbsp;
          <ListValue>{offer.timeLimit}</ListValue>
        </div>
      </FlexRow>
      <OfferLongContainer>
        <Typography variant="body" size="medium" letterSpacing="small">
          {offer.offerLong}
        </Typography>
      </OfferLongContainer>
      <CtaLink href={offer.ctaLink} target="blank" rel="noreferrer">
        <Button fullWidth>
          <FlexRow gap="8" justify="center">
            {offer.ctaLabel}
            <ArrowRightIcon />
          </FlexRow>
        </Button>
      </CtaLink>
    </OfferCardContainer>
  )
}
