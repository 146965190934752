import React, { useState } from 'react'
import styled from 'styled-components/macro'
import MuiMenu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { Button } from '../Button'
import { Link } from 'react-router-dom'

interface MenuConfigItem {
  label: string | React.ReactNode
  type: string
  href?: string
  to?: string
  onClick?: () => void
}

interface MenuConfig {
  label?: string | React.ReactNode
  items: MenuConfigItem[]
  notificationDot?: boolean
}

interface MenuProps {
  config?: MenuConfig
}

const StyledMenu = styled(MuiMenu)`
  .MuiMenu-paper {
    background-color: ${({ theme }) => theme.menu.bgColor};
    color: ${({ theme }) => theme.menu.color};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 6px 16px;
    border: 1px solid ${({ theme }) => theme.menu.borderColor};
  }

  .MuiButtonBase-root {
    &:hover {
      background: transparent;
    }
  }
`

const MenuButton = styled(Button)<{ notificationDot: boolean }>`
  padding-left: 0;
  padding-right: 0;

  position: relative;

  ::after {
    content: '';
    display: ${(props) => (props.notificationDot ? 'block' : 'none')};
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: red; /* TODO: theme */

    position: absolute;
    top: 0.85em;
    right: 0.5em;
  }
`

const MenuLink = styled.a`
  color: inherit;
  display: block;
  text-decoration: none;
  padding: 6px 16px;

  &:hover {
    color: ${({ theme }) => theme.menu.color};
    opacity: 0.7;
  }
`

const MenuLinkInternal = styled(Link)`
  color: inherit;
  display: block;
  text-decoration: none;
  padding: 6px 16px;

  &:hover {
    color: ${({ theme }) => theme.menu.color};
    opacity: 0.7;
  }
`

const MenuAction = styled.div`
  padding: 6px 16px;

  &:hover {
    opacity: 0.7;
  }
`

const StyledMenuItem = styled(MenuItem)`
  padding: 0 0;
`

function Menu({ config }: MenuProps) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  if (!config) {
    console.error('Menu requires config prop')
    return null
  }

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const label = config.label || 'Menu'
  const items = config.items

  return (
    <div>
      <MenuButton
        notificationDot={!!config.notificationDot}
        variant="tertiary"
        onClick={handleClick}
      >
        {label}
      </MenuButton>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          horizontal: 0,
          vertical: -11,
        }}
      >
        {items.map((item: MenuConfigItem, index: number) => {
          if (item.type === 'outsideLink') {
            return (
              <StyledMenuItem key={index} disableGutters={true}>
                <MenuLink key={index} target="_blank" href={item.href}>
                  {item.label}
                </MenuLink>
              </StyledMenuItem>
            )
          }

          if (item.type === 'insideLink') {
            return (
              <StyledMenuItem key={index} disableGutters={true}>
                <MenuLinkInternal to={item.to!}>{item.label}</MenuLinkInternal>
              </StyledMenuItem>
            )
          }

          return (
            <StyledMenuItem key={index} disableGutters={true}>
              <MenuAction onClick={item.onClick}>{item.label}</MenuAction>
            </StyledMenuItem>
          )
        })}
      </StyledMenu>
    </div>
  )
}

export { Menu }
export type { MenuProps }
