import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Button } from '@swell-ui/Button'
import { Drawer } from '@swell-ui/Drawer'
import { FlexRow } from '@swell-ui/FlexRow'
import { CloseIcon } from '@swell-ui/icons/CloseIcon'
import { HamburgerIcon } from '@swell-ui/icons/HamburgerIcon'
import { Logo } from '@swell-ui/branding/Logo'
import { Menu } from '@swell-ui/Menu'
import { TopNav } from '@swell-ui/TopNav'
import { useSwellUiTheme } from '@swell-ui/theme'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { TransakOnRampWidget } from '@/components/TransakOnRampWidget'
import { Web3NavWidget } from '@/components/Web3NavWidget'
import useAddToken from '@/hooks/useAddToken'
import { useSwEthToken, useRswEthToken } from '@/state/deployments/hooks'
import { useDepositSubmissionHistoricState } from '@/state/depositSubmission/hooks'
import { TopNavBarLinkStack } from './TopNavBarLinkStack'

const StyledTopNav = styled<any>(TopNav)`
  justify-content: flex-start;
  top: 0;
  left: 0;
  height: 120px;
  padding-left: 36px;
  padding-right: 36px;
  z-index: 10;
`

const StyledHamburgerIcon = styled(HamburgerIcon)`
  width: 40px;
  height: 40px;

  path {
    fill: ${({ theme }) => theme.mainColor};
  }
`

const DrawerLogo = styled(Logo)`
  height: 40px;
  width: 108px;
`

const DrawerInner = styled.div`
  padding-top: 20px;
`

const DrawerButton = styled(Button)`
  ${({ theme }) => `
    width: 100%;
    justify-content: left;

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.body.xlarge.fontSize};
      line-height: ${theme.typography.body.xlarge.lineHeight};
    }
  `}
`

function TopNavBarMenu() {
  const { account } = useSwellWeb3()
  const swEthToken = useSwEthToken()
  const rswEthToken = useRswEthToken()
  const { addToken, /*canWatchAssets,*/ success } = useAddToken({
    token: swEthToken,
  })
  const { addToken: addRswEthToken, success: addRswEthSuccess } = useAddToken({
    token: rswEthToken,
  })

  const { hasUnviewedHistoricSubmission } = useDepositSubmissionHistoricState()

  const config = {
    notificationDot: hasUnviewedHistoricSubmission,
    label: <StyledHamburgerIcon />,
    items: [
      {
        label: 'Documentation',
        type: 'outsideLink',
        href: 'https://docs.swellnetwork.io',
      },
      {
        label: 'Forum',
        type: 'outsideLink',
        href: 'https://forum.swellnetwork.io',
      },
      {
        label: 'GitHub',
        type: 'outsideLink',
        href: 'https://github.com/SwellNetwork',
      },
      {
        label: success ? 'Added swETH' : 'Add swETH',
        type: 'action',
        onClick: () => {
          addToken()
        },
      },
      {
        label: addRswEthSuccess ? 'Added rswETH' : 'Add rswETH',
        type: 'action',
        onClick: () => {
          addRswEthToken()
        },
      },
      {
        label: <TransakOnRampWidget label="Purchase ETH" account={account} />,
        type: 'action',
      },
    ],
  }

  if (hasUnviewedHistoricSubmission) {
    config.items.push({
      label: 'Previous key submission',
      type: 'insideLink',
      to: '/operators/validator-keys',
    } as any)
  }

  return <Menu config={config} />
}

function TopNavBarDrawer() {
  const [open, setOpen] = useState<boolean>(false)
  const { account } = useSwellWeb3()
  const swEthToken = useSwEthToken()
  const rswEthToken = useRswEthToken()
  const { addToken, /*canWatchAssets,*/ success } = useAddToken({
    token: swEthToken,
  })
  const { addToken: addRswEthToken, success: addRswEthSuccess } = useAddToken({
    token: rswEthToken,
  })

  const toggleDrawer =
    (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return
      }

      setOpen(isOpen)
    }

  const items: any = [
    {
      label: 'Docs',
      type: 'outsideLink',
      href: 'https://docs.swellnetwork.io',
    },
    {
      label: 'Forum',
      type: 'outsideLink',
      href: 'https://forum.swellnetwork.io',
    },
    {
      label: 'GitHub',
      type: 'outsideLink',
      href: 'https://github.com/SwellNetwork',
    },
    {
      label: success ? 'Added swETH' : 'Add swETH',
      type: 'action',
      onClick: () => {
        addToken()
      },
    },
    {
      label: addRswEthSuccess ? 'Added rswETH' : 'Add rswETH',
      type: 'action',
      onClick: () => {
        addRswEthToken()
      },
    },
    {
      label: <TransakOnRampWidget label="Purchase ETH" account={account} />,
      type: 'action',
    },
  ]

  return (
    <div>
      <div>
        <StyledHamburgerIcon onClick={toggleDrawer(true)} />
      </div>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <FlexRow justify="space-between" align="center">
          <DrawerLogo />
          <CloseIcon onClick={toggleDrawer(false)} />
        </FlexRow>
        <DrawerInner>
          {items.map((item: any, index: number) => {
            if (item.type === 'outsideLink') {
              return (
                <DrawerButton key={index} variant="tertiary">
                  <a href={item.href} target="_blank" rel="noreferrer">
                    {item.label}
                  </a>
                </DrawerButton>
              )
            }
            return (
              <DrawerButton
                key={index}
                variant="tertiary"
                onClick={item.onClick}
              >
                {item.label}
              </DrawerButton>
            )
          })}
        </DrawerInner>
      </Drawer>
    </div>
  )
}

function TopNavBar({ onlyLogo }: { onlyLogo?: boolean }) {
  const [pageIsScrolled, setPageIsScrolled] = useState<boolean>(false)

  const { theme } = useSwellUiTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'))
  const is1040Up = useMediaQuery('(min-width:1040px)')

  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        if (!pageIsScrolled && window.pageYOffset > 0) {
          setPageIsScrolled(true)
        }

        if (pageIsScrolled && window.pageYOffset === 0) {
          setPageIsScrolled(false)
        }
      },
      { passive: true }
    )
  })

  let middleComponents: (string | React.ReactNode)[] = []

  // only render top nav link stack if screen is wide enough
  if (isSmUp) {
    middleComponents = [<TopNavBarLinkStack />]
  }

  let rightComponents = [<TopNavBarDrawer />]

  if (is1040Up) {
    rightComponents = [<Web3NavWidget />, <TopNavBarMenu />]
  }

  return (
    <StyledTopNav
      pageIsScrolled={pageIsScrolled}
      leftComponent={<Logo />}
      middleComponents={onlyLogo ? [] : middleComponents}
      rightComponents={onlyLogo ? [] : rightComponents}
    />
  )
}

export { TopNavBar }
