import { useMemo } from 'react'
import { DepositCollectionValidator } from '.'
import { TaskRunnerSerial } from '../TaskRunner/TaskRunnerSerial'
import { useV3BackendClient } from '../V3BackendService/hooks'
import { ChainDepositConfig } from '@/state/deployments/types'

export const useDepositCollectionValidatorInstance = (
  validationBackendUrl: string,
  chainDepositConfig: ChainDepositConfig
) => {
  const {
    v3BackendClient: {
      operator: { validateKeys },
    },
  } = useV3BackendClient(validationBackendUrl)

  return useMemo(() => {
    return DepositCollectionValidator.create({
      taskRunner: TaskRunnerSerial.create(),
      chainDepositConfig,
      validateKeys,
    })
  }, [chainDepositConfig, validateKeys])
}
