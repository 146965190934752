import React from 'react'
import styled from 'styled-components/macro'
import MuiTextField, { TextFieldProps } from '@mui/material/TextField'

const StyledMuiTextField = styled<any>(MuiTextField)`
  input,
  textarea {
    color: ${({ theme }) => theme.mainColor};
  }

  div p {
    color: ${({ theme }) => theme.mainColor} !important;
  }

  svg {
    color: ${({ theme }) => theme.mainColor};
  }

  .MuiInputBase-root:before {
    border-color: ${({ theme }) => theme.input.underlineColor};
    border-bottom-width: 2px !important;
  }

  .MuiInputBase-root:after {
    border-color: ${({ theme }) => theme.input.underlineFocusColor};
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.colors.auxGrey};
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
  }

  .MuiInputBase-multiline:hover:not(.Mui-disabled)
    .MuiOutlinedInput-notchedOutline {
    border-color: rgba(66, 69, 97, 0.7);
  }

  .MuiInputBase-multiline.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #2f43ec;
  }

  .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before {
    border-bottom: 1px solid ${({ theme }) => theme.input.underlineColor};
    opacity: 0.7;
  }

  .MuiInputBase-root .MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: unset;
    color: ${({ theme }) => theme.mainColor};
  }

  .Mui-error {
    input {
      color: ${({ theme }) => theme.colors.red['100']};
    }
  }

  .MuiFormHelperText-root {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.typography.body.xsmall.fontSize};
    line-height: ${({ theme }) => theme.typography.body.xsmall.lineHeight};
    letter-spacing: -0.03em;
  }

  // variant="outlined" styling
  ${({ theme, variant }) =>
    variant === 'outlined' &&
    `
    // border color
    .MuiOutlinedInput-notchedOutline {
      border: ${theme.input.outlined.border};
    }

    // hover border color
    .MuiInputBase-root:hover {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.colors.lightBlue['75']};
      }
    }

    // focused border color
    .MuiInputBase-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${theme.colors.lightBlue['100']};
      }
    }
  `}
`

function TextInput(props: TextFieldProps) {
  return <StyledMuiTextField {...props} />
}

export { TextInput }
export type { TextFieldProps }
