import { formatWithConfig } from './number'

const ONE_BILLION = 1e9
const ONE_MILLION = 1e6
const ONE_THOUSAND = 1e3

export function abbreviateNumber(value: number): string {
  let decimal = value
  let abbreviationLetter = ''

  if (value >= ONE_BILLION) {
    decimal = value / ONE_BILLION
    abbreviationLetter = 'B'
  }
  if (value >= ONE_MILLION) {
    decimal = value / ONE_MILLION
    abbreviationLetter = 'M'
  } else if (value >= ONE_THOUSAND) {
    decimal = value / ONE_THOUSAND
    abbreviationLetter = 'K'
  }

  const formatted = formatWithConfig(decimal, { localize: false, precision: 2 })

  return `${formatted}${abbreviationLetter}`
}

export const displayPearls = (amt: number) => {
  if (amt === 0) return '0'
  const rounded = Math.round(amt)
  if (rounded < 1) return '< 1'
  return formatWithConfig(rounded, { localize: true, precision: 0 })
}

export const displayPercent = (value: number) => {
  return `${formatWithConfig(value * 100, { localize: false, precision: 2 })}%`
}
