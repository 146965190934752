import React from 'react'
import styled from 'styled-components/macro'
import MuiBox, { BoxProps as MuiBoxProps } from '@mui/material/Box'

interface BoxProps extends MuiBoxProps {
  color?: string
  type?: string
}

const StyledBox = styled(MuiBox)<BoxProps>`
  ${({ theme, color, type }) => `
    background-color: ${theme.box.bgColor};
    border-radius: 16px;
    border: 1px solid ${theme.box.borderColor};
    color:${theme.box.color};

    ${
      color === 'accent'
        ? `
      background-color: ${theme.accentBgColor};
    `
        : ``
    }

    ${
      type === 'clickable'
        ? `
      &:hover {
        cursor: pointer;
      }
    `
        : ``
    }
  `}
`

function Box(props: BoxProps) {
  return <StyledBox {...props} />
}

export { Box }
