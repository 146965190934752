import { DurationBreakdown, getDurationBreakdown } from '@/util/datetime'
import { useEffect, useState } from 'react'

function toTargetDuration(targetDate: Date) {
  const now = new Date().getTime()
  const durationMs = targetDate.getTime() - now
  return durationMs
}

export function useTimeCountdown(
  targetDateArg: string | Date | number | undefined
) {
  let targetDate: Date | undefined
  if (typeof targetDateArg === 'string') {
    targetDate = new Date(targetDateArg)
  } else if (typeof targetDateArg === 'number') {
    targetDate = new Date(targetDateArg)
  } else {
    targetDate = targetDateArg
  }

  const [countDownStats, setCountdownStats] = useState<
    DurationBreakdown | undefined
  >(() => {
    if (!targetDate) return undefined
    const durationMs = toTargetDuration(targetDate)
    return getDurationBreakdown(durationMs)
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (!targetDate) return
      const durationMs = toTargetDuration(targetDate)
      setCountdownStats(getDurationBreakdown(durationMs))
    }, 1000)

    return () => clearInterval(interval)
  }, [targetDate])

  return countDownStats
}
