import { ZapCall } from '@/state/zap/hooks'
import {
  GLOBAL_NOTIFICATION_TYPES,
  useGlobalNotification,
} from '@/swell-ui/GlobalNotification'
import { useEffect } from 'react'

export function useZapNotifications({
  zap,
  paused,
}: {
  zap: ZapCall
  paused: boolean
}) {
  const { error } = zap
  const { notify, removeNotification } = useGlobalNotification()

  useEffect(() => {
    if (!error || paused) return
    const id = notify(error, GLOBAL_NOTIFICATION_TYPES.ERROR)
    return () => removeNotification(id)
  }, [error, notify, removeNotification, paused])
}
