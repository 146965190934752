import MuiDrawer, { DrawerProps } from '@mui/material/Drawer'
import styled from 'styled-components/macro'

const StyledMuiDrawer = styled<typeof Drawer>(MuiDrawer)`
  ${({ theme }) => `
    .MuiModal-backdrop {
      background-color: ${theme.drawer.backdrop.bgColor};
      backdrop-filter: ${theme.drawer.backdrop.backdropFilter};
    }

    .MuiDrawer-paper {
      width: 100%;
      background-color: ${theme.drawer.bgColor};
      padding: 16px;
    }

    ${theme.breakpoints.up('sm')} {
      .MuiDrawer-paper {
        max-width: 360px;
      }
    }
  `}
`

function Drawer(props: DrawerProps) {
  return <StyledMuiDrawer {...props} />
}

export { Drawer }
