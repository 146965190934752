import React from 'react'
import { PageTitle } from '@/components/PageTitle'
import { OfferGrid } from '@/components/OfferGrid'

const TITLE = 'Bonus Offers'
const SUBTITLE =
  'Offers for users of Swell who stake with swETH or restake with rswETH.'

export function BonusOffers() {
  return (
    <>
      <PageTitle title={TITLE} subtitle={SUBTITLE} />
      <OfferGrid />
    </>
  )
}
