import React, { useState } from 'react'
import { RestakingWidget } from '@/components/StakingWidget/RestakingWidget'
import {
  useRouteParams,
  useZap,
  useZapBuilding,
  useZapContractAddress,
  useZapRoutes,
} from '@/state/zap/hooks'
import { prepareZap } from '@/state/zap/util'
import { TOKEN_LIST_ETH, TOKEN_LIST_RSWETH } from '@/constants/tokens'
import { Token } from '@/types/tokens'
import { useIERC20Contract } from '@/hooks/useContract'
import { useAllowance } from '@/hooks/erc20/useAllowance'
import { useApprove } from '@/hooks/erc20/useApprove'
import { useTokenBalances } from '@/hooks/erc20/useTokenBalances'
import { useEthBalance } from '@/state/user/hooks'
import { BigNumber } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import { trimDecimalPlaces } from '@/util/number'
import { useZapInputBehaviour } from './hooks'
import { useZapNotifications } from './notifications'

const DEFAULT_SLIPPAGE_PERCENT = 0.5
const MAX_PRICE_IMPACT_RATIO = 0.4

const ACTIONS = {
  RESTAKE: 'Restake',
  ZAP: 'Zap',
}

function Restaking() {
  const [action, setAction] = useState<string>(ACTIONS.ZAP)
  const [slippagePercent, setSlippagePercent] = useState<number>(
    DEFAULT_SLIPPAGE_PERCENT
  )

  const [srcTokenInputValue, setSrcTokenInputValue] = React.useState('')
  const [destTokenInputValue, setDestTokenInputValue] = React.useState('')
  const [srcToken, setSrcToken] = useState(TOKEN_LIST_ETH as Token)
  const destToken = TOKEN_LIST_RSWETH as Token
  const zapBuilding = useZapBuilding()

  let fromAmount: BigNumber | undefined
  if (srcTokenInputValue) {
    fromAmount = parseUnits(
      trimDecimalPlaces(srcTokenInputValue, srcToken.decimals),
      srcToken.decimals
    )
  }

  const routeParams = useRouteParams({
    fromAmount,
    fromToken: srcToken,
    toToken: destToken,
    slippage: slippagePercent / 100,
    maxPriceImpact: MAX_PRICE_IMPACT_RATIO,
  })

  let toAmount: BigNumber | undefined
  if (destTokenInputValue) {
    toAmount = parseUnits(
      trimDecimalPlaces(destTokenInputValue, destToken.decimals),
      destToken.decimals
    )
  }

  let routesPaused = !srcTokenInputValue && !destTokenInputValue
  if (action !== ACTIONS.ZAP) {
    routesPaused = true
  }

  const ethBalanceQuery = useEthBalance()
  const tokenBalancesQuery = useTokenBalances()
  const routeQuery = useZapRoutes(routeParams, { paused: routesPaused })

  const ethBalance = ethBalanceQuery.data?.balance
  const tokenBalancesData = tokenBalancesQuery.data
  const route = routeQuery.data

  const zapContractAddr = useZapContractAddress()
  const swapSrcTokenContract = useIERC20Contract(srcToken?.address)

  const zap = useZap()
  const allowance = useAllowance(swapSrcTokenContract, zapContractAddr)
  const approve = useApprove(swapSrcTokenContract, zapContractAddr)

  let fromTokenBalance: BigNumber | undefined = undefined
  if (tokenBalancesData) {
    const token = tokenBalancesData!.tokenBalances.find(
      (t: any) => t.address === srcToken.address
    )
    if (!token) {
      fromTokenBalance = BigNumber.from(0)
    } else {
      fromTokenBalance = token.balance
    }
  }

  const preparedZap = prepareZap({
    route,
    allowance: allowance.amount,
    fromTokenBalance,
    slippage: slippagePercent / 100,
  })

  useZapInputBehaviour({
    route,
    srcToken,
    setDestTokenInputValue,
    rswethDecimals: destToken.decimals,
    paused: action !== ACTIONS.ZAP,
  })
  useZapNotifications({ zap, paused: action !== ACTIONS.ZAP })

  return (
    <>
      <RestakingWidget
        stakingEnabled={{ isLoading: false, value: true }}
        destTokenInputValue={destTokenInputValue}
        preparedZap={preparedZap}
        setDestTokenInputValue={setDestTokenInputValue}
        setSrcTokenInputValue={setSrcTokenInputValue}
        srcTokenInputValue={srcTokenInputValue}
        zap={zap}
        srcToken={srcToken}
        destToken={destToken}
        slippagePercent={slippagePercent}
        setSlippagePercent={setSlippagePercent}
        defaultSlippagePercent={DEFAULT_SLIPPAGE_PERCENT}
        srcTokenBalance={fromTokenBalance ?? BigNumber.from(0)}
        ethBalance={ethBalance}
        tokenBalancesData={tokenBalancesData}
        action={action}
        setAction={setAction}
        setSrcToken={setSrcToken}
        allowance={allowance}
        approve={approve}
        zapBuilding={zapBuilding}
        defaultSrcToken={TOKEN_LIST_ETH}
        zapRoute={route}
        routeRefetching={routeQuery.isValidating}
        fromAmount={fromAmount}
        toAmount={toAmount}
      />
    </>
  )
}

export { Restaking }
