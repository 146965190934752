import React, { useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useSwellWeb3 } from '@swell-web3/core'
import { Stack } from '@swell-ui/Stack'
import { useSwellUiTheme } from '@swell-ui/theme'
import { useMediaQuery } from '@swell-ui/theme/useMediaQuery'
import { ConnectWalletButton } from '@/components/ConnectWalletButton'
import { useSwEthBalance, useRswEthBalance } from '@/state/user/hooks'
import { OperatorAccountButton } from './OperatorAccountButton'
import { RestakerAccountButton } from './RestakerAccountButton'
import { StakerAccountButton } from './StakerAccountButton'
import { EthAccountInfoWidget } from './EthAccountInfoWidget'
import { RestakingOperatorAccountButton } from './RestakingOperatorAccountButton'

const StyledButton = styled(ConnectWalletButton)`
  padding-left: 32.5px;
  padding-right: 32.5px;
`

function Web3NavWidget() {
  const { account } = useSwellWeb3()

  const swEthBalance = useSwEthBalance()
  const rswEthBalance = useRswEthBalance()

  const { theme } = useSwellUiTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'))

  const accountButton = useMemo(() => {
    if (!account) return null

    return (
      <Routes>
        <Route path="/operators/*" element={<OperatorAccountButton />} />
        <Route
          path="/restaking-operators/*"
          element={<RestakingOperatorAccountButton />}
        />
        <Route
          path="/stake"
          element={
            <StakerAccountButton
              swEthBalance={swEthBalance.data?.balance}
              loading={swEthBalance.isLoading}
            />
          }
        />
        <Route
          path="/restake"
          element={
            <>
              <RestakerAccountButton
                rswEthBalance={rswEthBalance.data?.balance}
                loading={rswEthBalance.isLoading}
              />
            </>
          }
        />
        <Route
          path="/portfolio"
          element={
            <>
              <StakerAccountButton
                swEthBalance={swEthBalance.data?.balance}
                loading={swEthBalance.isLoading}
              />
              <RestakerAccountButton
                rswEthBalance={rswEthBalance.data?.balance}
                loading={rswEthBalance.isLoading}
              />
            </>
          }
        />
        <Route path="/swell-l2" element={<></>} />
        <Route
          path="*"
          element={
            <>
              <StakerAccountButton
                swEthBalance={swEthBalance.data?.balance}
                loading={swEthBalance.isLoading}
              />
              <RestakerAccountButton
                rswEthBalance={rswEthBalance.data?.balance}
                loading={rswEthBalance.isLoading}
              />
            </>
          }
        />
      </Routes>
    )
  }, [account, swEthBalance, rswEthBalance])

  return (
    <div>
      {!account && !isMdDown && (
        <StyledButton size="small">Connect wallet</StyledButton>
      )}
      {account && (
        <Stack direction="row" alignItems="center">
          {accountButton}
          <EthAccountInfoWidget />
        </Stack>
      )}
    </div>
  )
}

export { Web3NavWidget }
